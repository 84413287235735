import React from "react";
import sidermenu from "../assets/images/SiderMenu.png";
import { useNavigate } from "react-router-dom";
import { api } from "../Services/api-service";
import notificationSvc from "../Services/notification";

const Cards = ({ match }) => {
  const navigate = useNavigate();

  const makeInteraction = async (e) => {
    e.preventDefault();
    const response = await api.post(`users/profiles/${match.id}/interact`);
    if (response && response.status === 200) {
      navigate("/chat", { state: match.user });
      notificationSvc.success("Proceeding successful Interaction");
    }
  };

  return (
    <>
      <div className="flex flex-col justify-around w-[365] border border-gray-200 rounded-[12px] px-5 py-5  bg-white">
        <div className="grid grid-cols-2 gap-[12px]  h-[50px] items-center justify-items-end mb-3">
          <div className="flex justify-self-start">
            <img
              src={match.user.profilePicture?.publicUrl || sidermenu}
              alt="SiderMenu"
              className="w-[65px] h-[65px] rounded-[70px]"
            />

            <div className="ml-3">
              <h5 className="mb-1 text-[18px] text-[#212121] font-[500]">
                {match.user.firstName}&nbsp;
              </h5>

              <p className="h-[23px] rounded-[41px] bg-spark-500 bg-opacity-10 px-2 pb-1 pt-[2px] text-spark-500  text-[12px] font-[400] text-center">
                {match.role}
              </p>
            </div>
          </div>
          <div className="self-center">
            <p className="mb-1 text-center text-[18px] text-[#212121] font-[500]">
              Score
            </p>
            <p className="text-sm font-bold text-center rounded-[41px] bg-spark-500 bg-opacity-10 px-2 pb-1 pt-[2px] text-spark-500 text-[12px] font-[400] w-[70px]">{match.score}%</p>
          </div>
        </div>
        <p className="text-[14px] pl-1 text-[#737373] mt-3 font-[400]">
          {match.user.tagline}
        </p>
        {/* {activePlan !== null && ( */}
        <div className="grid grid-cols-2 gap-4 p-2">
          <div>
            <span className="text-black font-bold text-sm">City</span>
            <p className="text-gray-500 text-sm">{match.user.city}</p>
          </div>

          <div>
            <span className="text-black font-bold text-sm">Gender</span>
            <p className="text-gray-500 text-sm">
              {match.user.selectedPronoun}
            </p>
          </div>

          <div>
            <span className="text-black font-bold text-sm">Skills</span>

            <p className="text-gray-500 text-sm">
              {match.skills?.join(", ")}
            </p>
          </div>
          <div>
            <span className="text-black font-bold text-sm">Looking for</span>
            <p className="text-gray-500 text-sm">{match.lookingFor}</p>
          </div>
        </div>
        {/* // )} */}
        <div className="border-t-[1px] my-3 border-[#EBEBEB]"></div>
        <div className="flex gap-2 justify-around mt-3">
          <button
            onClick={(e) => {
              makeInteraction(e);
            }}
            className="w-full rounded-[8px] text-center bg-spark-500 text-white text-[20px] font-[500] py-3 px-[16px]"
          >
            Message
          </button>
        </div>
      </div>
    </>
  );
};

export default Cards;
