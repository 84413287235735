import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../Services/api-service";

export const fetchActivePlan = createAsyncThunk(
  "activePlan/fetchActivePlan",

  async () => {
    const response = await api.get("plans/active");
    return response.data.plan;
  }
);

const activePlanSlice = createSlice({
  name: "activePlan",
  initialState: {
    activeplan: null,
    loading: false,
    error: null,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchActivePlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchActivePlan.fulfilled, (state, action) => {
        state.activeplan = action.payload;
        state.loading = false;
      })
      .addCase(fetchActivePlan.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export default activePlanSlice.reducer;
