import React from "react";
import NavbarHeader from "../../components/NavbarHeader.js";
import Footer from "../../components/footer";
const PrivacyPolicy = () => {
  return (
    <div className="">
      <NavbarHeader />
      <div className="pt-40 p-5 w-5/6 mx-auto">
        <h1 className="font-bold text-lg text-gray-600">Privacy Policy</h1>
        <p className="text-gray-500 text-sm mt-3 text-justify">
          Spark Founders LLC and Spark (“SPRK”), together with SPRK affiliates,
          parent company and subsidiaries (collectively, “we”, “us” or “our”),
          is the controller of your personal data under this Privacy Notice and
          is a services company offering subscription memberships for
          entrepreneurs to connect with others for the purpose of cofounding
          start- up companies (the “Business”). As a part of our operations and
          payment process, we gather certain types of information from and about
          online visitors and users through our website.
        </p>
        <p className="text-gray-500 text-sm mt-3 text-justify">
          This Privacy Policy applies to spark-founders.com, Spark-branded apps,
          Spark Learning & Accelerator Programs, Spark Advisors Network, and
          other Spark-related sites, apps, communications and services
          (“Services”). This Privacy Notice explains our practices with respect
          to personal data we collect and process. It applies to information
          collected from and about visitors and users (“you”) through our
          website operated by us (either directly or through our service
          providers) the respective web pages, sub-domains, and any other
          websites (and subdomains thereof) that we operate and that link to
          this Privacy Notice (collectively, the “Website”). This Privacy Notice
          also applies to information collected from or about you through email
          and other electronic submissions and communications between you and
          us, as well as to information collected at by our Business.
        </p>
        <p className="text-gray-500 text-sm mt-3 text-justify">
          This Privacy Notice does not apply to information collected by any
          other company or third-party site, application or content (including
          advertising) that may link to or be accessible from the Website, who
          may have their own policies that govern their use of your personal
          data; to the extent your personal data is transferred to us, depending
          on the purpose and basis on which it was transferred, it will be
          subject to this Privacy Notice or to the privacy terms of such service
          provider or partner. Certain transactions offered while using the
          Services may be processed or provided by service providers and other
          strategic partners, even though it appears that you may not have left
          the Website or our Business (e.g., service providers that provide
          payment processing). Please read this Privacy Notice carefully. It
          explains how we process and safeguard personal data about you. By
          visiting and/or by voluntarily providing personal data to us through
          or through or in connection with the Business, you acknowledge that
          you have read and understand the practices contained in this Privacy
          Notice. If you are providing information through the Website for or on
          behalf of a third party you hereby represent that you have the right
          to do so and that you have provided that party with a copy of this
          Privacy Notice, and all references to “you” or “your” data or personal
          data herein refers to the data or information of both you and such
          individual, as applicable.
        </p>
        <p className="text-gray-500 text-sm mt-3 text-justify">
          This Privacy Notice is subject to any agreements, including the Terms
          posted on our Website, that govern your use of our Website. Depending
          on your activities when visiting our Website, you may be required to
          agree to additional terms and conditions. We encourage you to review
          this Privacy Notice frequently, as it may change from time to time.
        </p>

        <p className="text-gray-500 text-sm mt-3 text-justify">
          A. TYPES OF INFORMATION COLLECTED ABOUT YOU AND HOW IT IS COLLECTED We
          and our service providers collect various types of information from
          and about you, including: “Personal data,” which is information about
          an identified or identifiable individual. This includes, for example,
          your name, email address, telephone numbers, company affiliation,
          title, physical address, screen names, credit card details (including
          the three or four digit security code on the back of your credit
          card), bank account or other payment information, credit information,
          birth date. Personal data also may include information that may not be
          personally identifiable standing alone (for example, your age), but
          which may become personally identifiable when combined with other
          personal data (for example, your age combined with your name).
          “Non-personal data” is information that is about you, but which does
          not, and cannot be used to, identify you individually and is not
          linked to any personal data, including: aggregated data, such as
          information regarding use of our Website (including clickstream data,
          your movement within and interaction with the Website, including what
          pages you look at, and the time, date, and duration of your visit to
          the Website) and traffic patterns relating to the Website.
          Non-personal data may include personal data that has been anonymized
          in such a manner that it cannot be used, alone or in connection with
          other information, to identify you. Non-personal data also may be
          aggregated with non-personal data about others to create aggregate
          data. We may collect this information through various means,
          including: (a) directly from you when you provide it to us, for
          example, via forms that you fill out on the Website, through emails
          and other communications and information that you submit via the
          Website, and through your other activities on the Website; (b)
          automatically as you navigate through and interact with the Website,
          including via server logs, cookies, pixels, beacons, analytics, and
          other tracking technologies; and (c) from third parties, including
          without limitation our service providers, analytics providers,
          intermediaries, analytics providers, and through your interactions
          with us on or involving social media websites or accounts. Information
          You Share With Us The information we collect may include the following
          types of personal data that you share with us: 1. Information you
          provide when you register for the Website, make a purchase,
          participate in an offering made available on the Website, including
          without limitation your contact information (such as your name,
          address, zip code, email address, and/or phone numbers), your screen
          name or username, and your payment information (such as your credit
          card details, bank and bank account routing information, or other
          information); 2. Your demographic information, such as your age,
          gender, marital status, number of children, and nationality; 3.
          Information that you share in response to surveys and other market
          research; 4. Information that you include in an email, general
          comment, or recommendation sent through on our Website, as well as
          records and copies of that correspondence (including email addresses);
          5. If you choose to log into the Website through social sign ins,
          personal data collected from your social media account consistent with
          your settings within the applicable social media service, such as
          photos, name, gender, contact information, and other information; and
          6. Other personal data that you choose to provide to us including but
          not limited to as outline below. . You have choices about the
          information on your profile, such as your education, work experience,
          skills, photo, city or area and endorsements. You don’t have to
          provide additional information on your profile; however, profile
          information helps you to get more from our Services, including helping
          recruiters and business opportunities find you. It’s your choice
          whether to include sensitive information on your profile and to make
          that sensitive information public. Please do not post or add personal
          data to your profile that you would not want to be publicly available.
          You give other data to us, such as by syncing your address book or
          calendar. We collect personal data from you when you provide, post or
          upload it to our Services, such as when you fill out a form, (e.g.,
          with demographic data or salary), respond to a survey, or submit a
          resume. If you opt to import your address book, we receive your
          contacts (including contact information your service provider(s) or
          app automatically added to your address book when you communicated
          with addresses or numbers not already in your list). If you sync your
          contacts or calendars with our Services, we will collect your address
          book and calendar meeting information to keep growing your network by
          suggesting connections for you and others, and by providing
          information about them, e.g. times, places, attendees and contacts.
          You don’t have to post or upload personal data; though if you don’t,
          it may limit your ability to grow and engage with your network over
          our Services. Others may post or write about you. You and others may
          post content that includes information about you (as part of articles,
          posts, comments, videos) on our Services. Unless you opt-out, we
          collect public information about you, such as professional-related
          news and accomplishments (e.g., patents granted, professional
          recognition, conference speakers, projects, etc.) and make it
          available as part of our Services Others may sync their contacts or
          calendar with our Services. We receive personal data (including
          contact information) about you when others import or sync their
          contacts or calendar with our Services, associate their contacts with
          Member profiles, or send messages using our Services (including
          invites or connection requests). If you or others opt-in to sync email
          accounts with our Services, we will also collect “email header”
          information that we can associate with Member profiles. Others may
          sync their contacts or calendar with our Services. If you communicate
          through our Services, we learn about that. We collect information
          about you when you send, receive, or engage with messages in
          connection with our Services. For example, if you get a Spark
          connection request, we track whether you have acted on it and will
          send you reminders. We also use automatic scanning technology on
          messages. When your employer or school buys a premium Service for you
          to use, they give us data about you. Others buying our Services for
          your use, such as your employer or your school, provide us with
          personal data about you and your eligibility to use the Services that
          they purchase for use by their workers, students or alumni. For
          example, we will get contact information for “Company Page”
          administrators and for authorizing users of our premium Services, such
          as our recruiting, sales or learning products. We get data when you
          visit sites that include our plugins, ads or cookies or log-in to
          others’ services with your Spark account. We receive information about
          your visits and interaction with services provided by others when you
          log-in with Spark or visit others’ services that include our plugins
          (such as “Share”), ads, cookies or similar technologies. Whether you
          provide this information is your choice; however, please bear in mind
          that in many instances certain personal data is necessary for you to
          participate in a particular activity, for us to provide a certain
          service or benefit to you, or for you to gain access to certain
          features, services or content on our Website. If you elect not to
          provide us with certain personal data, you may not be able to
          participate in a particular activity, realize a benefit we may offer,
          or gain access to certain content, functionalities or services.
          Services Our Services help you connect with others, find and be found
          for work and business opportunities, stay informed, get training and
          be more productive. We use your data to authorize access to our
          Services. Stay Connected Our Services allow you to stay in touch and
          up to date with colleagues, partners, clients, and other professional
          contacts. To do so, you will “connect” with the professionals who you
          choose, and who also wish to “connect” with you. Subject to your
          settings, when you connect with other Members, you will be able to
          search each others’ connections in order to exchange professional
          opportunities. We will use data about you (such as your profile,
          profiles you have viewed or data provided through address book uploads
          or partner integrations) to help others find your profile, suggest
          connections for you and others (e.g. Members who share your contacts
          or job experiences) and enable you to invite others to become a Member
          and connect with you. You can also opt-in to allow us to use your
          precise location or proximity to others for certain tasks (e.g. to
          suggest other nearby Members for you to connect with, calculate the
          commute to a new job, or notify your connections that you are at a
          professional event). It is your choice whether to invite someone to
          our Services, send a connection request, or allow another Member to
          become your connection. When you invite someone to connect with you,
          your invitation will include your name, photo, network and contact
          information. We will send invitation reminders to the person you
          invited. Stay Informed Our Services allow you to stay informed about
          news, events and ideas regarding professional topics you care about,
          and from professionals you respect. Our Services also allow you to
          improve your professional skills, or learn new ones. We use the data
          we have about you (e.g., data you provide, data we collect from your
          engagement with our Services and inferences we make from the data we
          have about you), to recommend relevant content and conversations on
          our Services, suggest skills you may have to add to your profile and
          skills that you might need to pursue your next opportunity. So, if you
          let us know that you are interested in a new skill (e.g., by watching
          a learning video), we will use this information to personalize content
          in your feed, suggest that you follow certain members on our site, or
          watch related learning content to help you towards that new skill. We
          use your content, activity and other data, including your name and
          picture, to provide notices to your network and others. For example,
          subject to your settings we may notify others that you have updated
          your profile, posted a blog, took a social action made new connections
          or mentioned in the news. Career Our Services allow you to explore
          careers, evaluate educational opportunities, and seek out, and be
          found for, career opportunities. Your profile can be found by those
          looking to partner or be hired by you. We will use your data to
          recommend jobs or mentees, show you and others who work at a company,
          in an industry, function or location or have certain skills and
          connections. You can signal that you are interested in changing jobs
          and share information with job recruiters. We will use your data to
          recommend jobs to you and you to recruiters. We may use automated
          systems to profile and provide recommendations to help make our
          Services more relevant to our Members, Visitors and customers. Keeping
          your profile accurate and up-to-date may help you better connect to
          others and to opportunities through our Services. Productivity Our
          Services allow you to collaborate with colleagues, search for
          potential clients, customers, partners and others to do business with.
          Our Services allow you to communicate with other Members and schedule
          and prepare meetings with them. If your settings allow, we scan
          messages to provide “bots” or similar tools that facilitate tasks such
          as scheduling meetings, drafting responses, summarizing messages or
          recommending next steps. Premium Services Our premium Services allow
          paying users to search for and contact Members through our Services,
          such as searching for and contacting job candidates, sales leads and
          co-workers, manage talent and promote content through social media. We
          sell premium Services that provide our customers and subscribers
          customized-search functionality and tools (including messaging and
          activity alerts) as part of our talent, marketing and sales solutions.
          Customers can export limited information from your profile, such as
          name, headline, current company, current title, and general location
          (e.g., Dublin), in order to manage sales leads or talent, unless you
          opt-out. We do not provide contact information to customers as part of
          these premium Services without your consent. A premium Services
          customer can store information he/she has about you in our premium
          Services, such as a resume or contact information or sales history.
          The data provided about you by these customers is subject to the
          policies of those customers. Communications We contact you and enable
          communications between Members. We offer settings to control what
          messages you receive and how often you receive some types of messages.
          We will contact you through email, mobile phone, notices posted on our
          websites or apps, messages to your Spark inbox, and other ways through
          our Services, including text messages and push notifications. We will
          send you messages about the availability of our Services, security, or
          other service-related issues. We also send messages about how to use
          the Services, network updates, reminders, job suggestions and
          promotional messages from us and our partners. You may change your
          communication preferences at any time. Please be aware that you cannot
          opt-out of receiving service messages from us, including security and
          legal notices. We also enable communications between you and others
          through our Services, including for example discussion and messages
          between connections. Advertising We serve you tailored ads both on and
          off our Services. We offer you choices regarding personalized ads, but
          you cannot opt-out of seeing other ads. We target (and measure the
          performance of) ads to Members, Visitors and others both on and off
          our Services directly or through a variety of partners, using the
          following data, whether separately or combined: Data from advertising
          technologies on and off our Services, like web beacons, pixels, ad
          tags, cookies, and device identifiers; Member-provided information
          (e.g., profile, contact information, title and industry); Data from
          your use of our Services (e.g., search history, feed, content you
          read, who you follow or is following you, connections, groups
          participation, page visits, videos you watch, clicking on an ad,
          etc.), including as described in Section 1.3; Information from
          advertising partners and publishers; and Information inferred from
          data described above (e.g., using job titles from a profile to infer
          industry, seniority, and compensation bracket; using graduation dates
          to infer age or using first names or pronoun usage to infer gender).
          We will show you ads called sponsored content which look similar to
          non-sponsored content, except that they are labeled “ads” or
          “sponsored.” If you take an action (such as like, comment or share) on
          these ads, your action is associated with your name and viewable by
          others, including the advertiser. Subject to your settings, if you
          take a social action on the Spark Services, that action may be
          mentioned with related ads. Ad Choices We adhere to self-regulatory
          principles for interest-based advertising and participate in industry
          opt-outs from such ads. This does not opt you out of receiving
          advertising; you will continue to get other ads by advertisers not
          listed with these self regulatory tools. You can also opt-out
          specifically from our uses of certain categories of data to show you
          more relevant ads. Info to Ad Providers We do not share your personal
          data with any third-party advertisers or ad networks for their
          advertising except for: (i) hashed or device identifiers (to the
          extent they are personal data in some countries); (ii) with your
          separate permission (e.g., lead generation form) or (iii) data already
          visible to any users of the Services (e.g. profile). However, if you
          view or click on an ad on or off our site or apps, the ad provider
          will get a signal that someone visited the page that displayed the ad,
          and they may through the use of mechanisms such as cookies determine
          it is you. Advertising partners can associate personal data collected
          by the advertiser directly from you with our cookies and similar
          technologies. In such instances, we seek to contractually require such
          advertising partners to obtain your explicit, opt-in consent before
          doing so. Service Development We use data, including public feedback,
          to conduct research and development for the further development of our
          Services in order to provide you and others with a better, more
          intuitive and personalized experience, drive membership growth and
          engagement on our Services, and help connect professionals to each
          other and to economic opportunity. Other Research We seek to create
          economic opportunity for Members of the global workforce and to help
          them be more productive and successful. We use the personal data
          available to us to research social, economic and workplace trends such
          as jobs availability and skills needed for these jobs and policies
          that help bridge the gap in various industries and geographic areas.
          In some cases, we work with trusted third parties to perform this
          research, under controls that are designed to protect your privacy. We
          publish or allow others to publish economic insights, presented as
          aggregated data rather than personal data. Surveys Polls and surveys
          are conducted by us and others through our Services. You are not
          obligated to respond to polls or surveys, and you have choices about
          the information you provide. You may opt-out of survey invitations.
          Customer Support We use data to help you and fix problems. We use the
          data (which can include your communications) to investigate, respond
          to and resolve complaints and Service issues (e.g., bugs). We share
          your data across our different Services and Spark-affiliated entities.
          We will share your personal data with our affiliates to provide and
          develop our Services. We may combine information internally across the
          different Services covered by this Privacy Policy to help our Services
          be more relevant and useful to you and others. Service Providers We
          may use others to help us with our Services. We use others to help us
          provide our Services (e.g., maintenance, analysis, audit, payments,
          fraud detection, marketing and development). They will have access to
          your information as reasonably necessary to perform these tasks on our
          behalf and are obligated not to disclose or use it for other purposes.
          Aggregate Insights We use data to generate aggregate insights. We use
          your data to produce and share aggregated insights that do not
          identify you. For example we may use your data to generate statistics
          about our members, their profession or industry, to calculate ad
          impressions served or clicked on, or to publish visitor demographics
          for a Service or demographic workforce insights. Security and
          Investigations We use data for security, fraud prevention and
          investigations. We use your data (including your communications) if we
          think it’s necessary for security purposes or to investigate possible
          fraud or other violations of our User Agreement or this Privacy Policy
          and/or attempts to harm our Members or Visitors.
          Automatically-Collected Information When you visit and as you navigate
          through and interact with the Website, we and third parties may
          automatically collect information about your visits to our Website,
          your equipment, and your browsing action and patterns and your
          activities on the Website. This information may include, but is not
          limited to: Details of your visits to our Website including mobile
          apps and the resources that you access, such as traffic data, location
          data, logs and other communication data, the third-party website from
          which your visit originated, clickstream data, the search terms you
          use on our Website, your movement within and interaction with the
          Website, including what pages you look at, and the time, date, and
          duration of your visit to the Website. Information about your
          computer or devices and Internet connection, such as your IP address,
          your general geographic location, your computer or device type and its
          capabilities and features, the operating system or platform you are
          using, your browser type, and your Internet Service Provider.
          Information about traffic patterns, number of visits to certain
          pages, visits from other websites or to third-party websites linked to
          the Website, and visitors’ use of particular services and interest in
          services, information or features of the Website We log usage data
          when you visit or otherwise use our Services, including our sites, app
          and platform technology (e.g., our off-site plugins), such as when you
          view or click on content (e.g., learning video) or ads (on or off our
          sites and apps), perform a search, install or update one of our mobile
          apps, share articles or apply for jobs. We use log-ins, cookies,
          device information and internet protocol (“IP”) addresses to identify
          you and log your use. Automatically-collected information often is
          processed to create aggregated statistical or demographic data. We may
          share such aggregated data with third
          parties.  Automatically-collected information that contains or
          constitutes personal data, as well as non-personal data that we
          maintain or associate with personal data collected in other ways or
          received from third parties, is be treated as personal data and used
          and shared as disclosed in this Privacy Notice. Cookies and Other
          Tracking Technologies We and third parties may place “cookies”and
          similar technologies on our Website to collect certain information. A
          “cookie” or “browser cookie” is a small text file that can be stored
          on your computer’s hard drive or other device, if your web browser or
          device settings permit. Certain features of the Website also may use
          local stored objects (or Flash Cookies) to collect and store
          information, including information about your preferences and
          navigation to, from, and on our Website. Cookies allow us and our
          service providers, for example, to customize your experience and make
          your visit to the Website easier (for example, recognizing when you
          return, remembering your viewing preferences, enabling social media
          sign in functionality, facilitating social sharing, and, if you are a
          registered user, remembering your password), measure activity on the
          Website, track conversions, target ads, and provide analytics. While
          some cookies are deleted when you close your browser, others remain
          even after you close your browsing session. . We and other third
          parties also may use pixel tags, web beacons or clear gifs, which are
          tiny files with a unique identifier, similar in function to cookies
          that are placed in the code of a web page or an email. These
          technologies might be used, for example, to allow us and our service
          providers to track conversions, to send emails to you in a format you
          can read, to count users who have visited our Website, seen our ads,
          opened our emails or clicked on any links in our emails, to inform us
          what content and advertising is effective, to optimize the Website and
          its content, to reduce or eliminate messages sent to customers, to
          enhance Website usability, to provide you with ads and other
          promotions and content that may be of interest to you based on your
          online activities, and to collect other information about you and your
          visit when you use the Website. In addition, when you click on a link
          in an email, we or our service providers may record the individual
          response to allow us to customize our offerings to you. We may analyze
          the information derived from cookies, pixels and other technologies
          and match or combine that information with data provided by you or
          another party. We engage various service providers who may use unique
          identifiers, cookies, pixels, beacons, and other technologies to
          provide us with analytics about how and in what ways you interact with
          us and use the Website, and to deliver advertisements and content that
          may be of interest to you (known as “interest-based advertising” or
          “online behavioral advertising”). To the extent third parties are
          using pixels, cookies, beacons, or other tracking technologies, we do
          not control the use of those technologies or the resulting
          information, and we are not responsible for the policies of those
          companies. Please keep in mind, however, that you may not be able to
          access the full functionality of our Website if cookies are disabled.
          Information Obtained from Third Parties We receive personal data from
          third parties that provide web analytics and usage information to us.
          In addition, if you choose to interact with us on social media
          platforms by posting to our social media accounts or pages, tagging us
          (or using certain hashtags or other identifiers) in posts, or
          participating in activities, we may collect certain information from
          the social media account you use to interact with us depending on the
          social media platform and your account settings, including the name
          associated with the account, the account handle, recent activity, the
          content of any posts in which we are tagged, and other information
          that may be contained on your social media profile to allow us to
          respond to the posts and understand and engage with our audience. For
          the purposes of this Privacy Notice, “partners” means licensors,
          franchisees, vendors, service providers, and other third parties] with
          which we have a business relationship. B. HOW WE PROCESS PERSONAL
          DATA  Legal Bases for Processing We process personal data for, or
          based on, one or more of the following legal bases: Performance of a
          Purchase. We use personal data to enter into and to perform under, an
          agreement between us, such as when you make a purchase. Legitimate
          Interests. We use personal data for our legitimate interests,
          including contact information, buying history and inquiry history, and
          website usage data. Compliance with Legal Obligations and Protection
          of Individuals. We may use personal data to comply with the law and
          our legal obligations, as well as to protect you and other individuals
          from certain harms. Your Consent. We process certain personal data
          because you have given us your consent to process it in that manner.
          Operational Processing of Personal Data We may process non-personal
          data (including aggregated information) for any lawful purpose.  In
          addition, we may use personal data collected from or about you as
          described in this Privacy Policy for the purposes disclosed to you or
          as otherwise permitted or required by law, including: To provide,
          track, and process the purchases and transactions that you request;
          To validate your identity, to verify communications from you, and to
          respond to your emails, questions, comments, requests for information,
          and complaints and to provide customer service; To send you updates
          and information, such as communications about purchases, programs you
          have joined, and changes to our terms, conditions and policies; To
          conduct customer satisfaction, market research or quality assurance
          surveys ; To conduct market research; To conduct and administer
          sweepstakes, prize draws and other contests; For marketing purposes,
          including without limitation interest-based advertising, other
          targeted promotions, sending you other promotional materials, and
          notifying you about special promotions, offers, events or
          opportunities, including for select marketing partners of ours; To
          send you marketing communications about the products, marketing
          partners and other third parties; To cross-reference, supplement and
          combine with other information that we have acquired about you or may
          acquire about you through other sources, except where prohibited by
          applicable law or regulation; To monitor, track, and analyze Website
          usage and trends, to personalize and improve the Website, and to
          increase the Website’s functionality; To send you confirmations,
          updates, security alerts, and support and administrative messages and
          otherwise facilitate your use of, and our administration and operation
          of, the Website; To protect the security or integrity of our network,
          systems, Website, and business; To prevent fraud and other prohibited
          or illegal activities, to investigate and resolve disputes and
          problems, and to otherwise comply with applicable laws and
          regulations; To enforce our Terms of Use and other contractual
          obligations; For any other purpose that is disclosed to you at the
          point of collection of the personal data; For any purpose for which
          you provide your prior consent; and In other ways naturally
          associated with the circumstances in which you provided the personal
          data, and for any other lawful purpose. In addition, we use IP
          addresses for purposes of system administration, for Website
          optimization, Website security assessments, to report non-personal
          aggregate information to others, to track the use of our Website, and
          to get a sense of where our Website visitors are located. We also we
          use your IP address to personalize content on the Website and to
          identify you. How We Share Your Information We may share non-personal
          data with any third parties for any lawful purpose. Unless otherwise
          prohibited by law, we disclose personal data collected from or about
          you as described in this Privacy Notice to the following types of
          third parties and for the following purposes: Affiliated
          Companies: We may share your information with our affiliated companies
          and other business ventures that are licensed, owned, operated, and/or
          managed by us and other companies under common control, who may use
          personal data for any of the purposes disclosed in or consistent with
          the terms of this Privacy Notice Marketing Partners: We may share
          your personal data with our co-promotional partners and other business
          partners with whom we have marketing or other relationships for joint
          marketing purposes and your use of the Website acknowledges or
          consents to the direct provision of your personal data to such parties
          when you provide it to us. Service Providers:  We may share your
          information with our service providers who assist us by performing
          core services (such as hosting, billing, fulfillment, data storage,
          security, processing credit card payments, customer service, credit
          reporting, accounting, administering sweepstakes, surveys, email and
          mailing services, and delivering packages) related to the operation of
          the Business and the operation of the Website, the processing and
          fulfillment of product orders, and/or by making certain Website
          functionality available to our users. Transactions: In the event of a
          sale, merger, consolidation, change in control, transfer of
          substantial assets, bankruptcy, reorganization, or liquidation, we may
          transfer, sell, or assign to third parties information concerning your
          relationship with us, including, without limitation, personal data
          collected from or about you provide, and other information concerning
          your relationship with us. Any successor may use your information for
          the same purposes set forth in this Privacy Notice. Legal: We reserve
          the right to your personal data to identify you and, where permitted
          or required by law or applicable regulation, we will provide any
          information about or relating to you, including any personal data
          about you, to third parties if we reasonably believe that such action
          is necessary to: (a) comply with a court order, subpoena or other
          legal or regulatory requirements; (b) otherwise fulfill a government
          request for information; (c) protect or defend our legal rights, our
          Website, or other online users; (d) respond to claims that any posting
          or other content of our Website violates the rights of third parties,
          including without limitation providing information necessary to
          satisfy the notice and counter-notice procedures pursuant to the
          Digital Millennium Copyright Act; (e) in an emergency, to protect the
          health and safety of our Website’s users or the general public; or (f)
          enforce compliance with our Terms of Use or other contracts. Purpose
          Fulfillment: We disclose personal data to fulfill the purpose for
          which you provide it. Consent: We disclose personal data with your
          consent and/or when you request that we do so. C.RETENTION OF
          PERSONAL DATA We retain personal data for as long is necessary or
          appropriate to fulfil the purpose for which it was collected, as well
          to the extent necessary or appropriate to carry out the processing
          activities described above, including but not limited to compliance
          with applicable laws, regulations, rules and requests of relevant law
          enforcement and/or other governmental agencies, and to the extent we
          reasonably deem necessary to protect our and our partners’ rights,
          property, or safety, and the rights, property, and safety of our users
          and other third parties. We consider multiple factors, including legal
          requirements, the scope and nature of the personal data, and potential
          risk of harm to data subjects from a data breach, in determining the
          appropriate retention period. If you have a specific question
          regarding retention of your personal data, you may contact us. We
          retain your personal data while your account is in existence or as
          needed to provide you Services. This includes data you or others
          provided to us and data generated or inferred from your use of our
          Services. Even if you only use our Services when looking for a new
          Spark every few years, we will retain your information and keep your
          profile open until you decide to close your account. If you choose to
          close your Spark account, your personal data will generally stop being
          visible to others on our Services within 24 hours. We generally delete
          closed account information within 30 days of account closure, except
          as noted below. We retain your personal data even after you have
          closed your account if reasonably necessary to comply with our legal
          obligations (including law enforcement requests), meet regulatory
          requirements, resolve disputes, maintain security, prevent fraud and
          abuse, enforce our User Agreement, or fulfill your request to
          “unsubscribe” from further messages from us. We will retain
          de-personalized information after your account has been closed.
          Information you have shared with others (e.g., through messages,
          updates or discussion posts) will remain visible after you closed your
          account or deleted the information from your own profile or mailbox,
          and we do not control data that other Members copied out of our
          Services. Groups content and ratings or review content associated with
          closed accounts will show an unknown user as the source. Your profile
          may continue to be displayed in the services of others (e.g., search
          engine results) until they refresh their cache. D. YOUR RIGHTS
          REGARDING PERSONAL DATA You have a variety of legal rights regarding
          the collection and processing of personal data. You may exercise these
          rights, to the extent they apply to you, by contacting us, or by
          following instructions provided in this Privacy Notice or in
          communications sent to you. Please be prepared to provide reasonable
          information to identify yourself and authenticate your requests. Note,
          however, that we may request certain reasonable additional information
          (that may include personal data) to help us authenticate the request
          and/or to clarify or understand the scope of such requests. These
          rights vary depending on the particular laws of the jurisdiction
          applicable to you, but may include: The right to know whether, and
          for what purposes, we process personal data about you; The right to
          be informed about the personal data we collect and/or process about
          you; The right to learn the source of personal data about you we
          process, where we obtain that personal data from a source other than
          you. The right to access, modify, and correct personal data about
          you. The right to know with whom we have shared personal data about
          you, for what purposes, and what personal data has been shared
          (including whether personal data was disclosed to third parties for
          their own direct marketing purposes); Where processing of personal
          data about you is based on consent, the right to withdraw your consent
          to such processing; and The right to lodge a complaint with a
          supervisory authority located in the jurisdiction of your habitual
          residence, place of work, or where an alleged violation of law
          occurred. Accessing, Modifying, Rectifying, and Correcting Collected
          Personal data We strive to maintain the accuracy of any personal data
          collected from you, and will use commercially reasonable efforts to
          respond promptly to update our database when you tell us the
          information in our database is not accurate. However, we must rely
          upon you to ensure that the information you provide to us is complete,
          accurate, and up-to-date, and to inform us of any changes. Please
          review all of your information carefully before submitting it to us,
          and notify us as soon as possible of any updates or corrections. In
          accordance with applicable law, you may obtain from us certain
          personal data in our records. You can contact us as described below to
          access, review, update, or correct certain personal data. Please note,
          however, that we reserve the right to deny access as permitted or
          required by applicable law. Your Privacy Rights In addition to the
          above-listed rights, certain laws provide individuals with enhanced
          rights in respect of their personal data. These rights may include,
          depending on the facts and circumstances surrounding, and in certain
          cases the legal basis for, the processing of personal data, following:
          The right to be informed of whether we hold personal data about you.
          The right to object to decisions based on profiling or automated
          decision making that produce legal or similarly significant effects on
          you; The right to request restriction of processing of personal data
          or object to processing of personal data carried out pursuant to (a) a
          legitimate interest (including, but not limited to, processing for
          direct marketing purposes) or (b) performance of a task in the public
          interest; The right to challenge the accuracy and completeness of
          your personal data and have it amended as appropriate; The right to
          be provided with information about our policies and practices with
          respect to the management of personal data, including: (a) the name or
          title, and address, of the person who is accountable for our privacy
          policies and practices; (b) the means of gaining access to personal
          data; (c) a description of the type of personal data held by us,
          including a general account of its use; (d) a copy of any brochures or
          other information that explain our policies, standards, or codes; and
          (e) what personal data is made available to related organizations;
          The right to data portability, which means that you can request that
          we provide certain personal data we hold about you in a
          machine-readable format; and The right to erasure and/or the right to
          be forgotten, which means that you can request deletion or removal of
          certain personal data we process about you. Where required by
          applicable law, we will respond to a valid request relating to your
          rights within one month of receipt, or within three months where a
          request is complex or challenging. Note that we may need to request
          additional information from you to validate your request. You can
          access or delete your personal data. You have many choices about how
          your data is collected, used and shared.  For personal data that we
          have about you: Delete Data: You can ask us to erase or delete all or
          some of your personal data (e.g., if it is no longer necessary to
          provide Services to you). Change or Correct Data: You can edit some
          of your personal data through your account. You can also ask us to
          change, update or fix your data in certain cases, particularly if it’s
          inaccurate. Object to, or Limit or Restrict, Use of Data: You can ask
          us to stop using all or some of your personal data (e.g., if we have
          no legal right to keep using it) or to limit our use of it (e.g., if
          your personal data is inaccurate or unlawfully held). Right to Access
          and/or Take Your Data: You can ask us for a copy of your personal data
          and can ask for a copy of personal data you provided in machine
          readable form. You may also contact us using the contact information
          below, and we will consider your request in accordance with applicable
          laws. E. YOUR CHOICES In certain instances, we may provide you with
          the option to set your preferences for receiving email communications
          from us; that is, you can agree to receive some communications but not
          others. At any time, you also may opt-out of receiving future
          commercial emails from us by following the “Unsubscribe” instructions
          contained in any commercial emails that you receive from us.  You also
          may contact us if you want to opt-out from receiving future commercial
          correspondence, including marketing emails, from us. If applicable and
          appropriate, make the requested change in our active databases within
          a reasonable timeframe in compliance with applicable law. Please
          remember that we may not be able to fulfill certain requests while
          allowing you access to certain benefits and features. Even if you
          unsubscribe from our marketing communications, you may continue to
          receive certain communications from us, such as transactional,
          customer service or other relationship messages, messages about your
          account and profile, and emails in response to communications or
          requests for information that we receive from you. If you want to
          limit or prevent our ability to collect location information from you,
          you can deny or remove the permission for the Website to access
          location information or deactivate location services on your device.
          Please refer to your device manufacturer or operating system
          instructions for instructions on how to do this. F. INTEREST-BASED
          ADVERTISING We may allow third parties to collect information about
          your online activities on our Website through unique identifiers,
          cookies, pixels, beacons and other technologies (as described above).
          These third parties include advertising networks that may use unique
          identifiers, cookies, pixels, beacons, and other technologies to track
          and collect information about your interests when you visit our
          Website and other websites or view or interact with one of the
          advertisements they place on various websites or mobile platforms. The
          information gathered by these third parties is used to make
          predictions about your characteristics, interests or preferences and
          to display advertisements on our Website and across the Internet
          tailored to your apparent interests. G. ANALYTICS We engage service
          providers to provide analytics data for the purposes of understanding
          and optimizing web usage, for business and market research purposes,
          measuring conversion tracking, and measuring the effectiveness of our
          content and online advertising campaigns. These service providers may
          use cookies alone or in conjunction with unique identifiers, pixels,
          beacons and other tracking technologies to collect information about
          you when you use the Website. H. PUBLIC FORUMS We may offer blogs,
          message boards, bulletin boards, or similar public forums where you
          and other users of our Website can communicate. Unless otherwise
          required by applicable law or regulation, the protections described in
          this Privacy Notice do not apply to information (including personal
          data) that you post to these public forums. We may use personally
          identifiable and non-personal data about you to identify you through
          your postings in a public forum. Any information you share in a public
          forum is public information and may be seen or collected by anyone,
          including third parties that do not adhere to our Privacy Notice. We
          are not responsible for events arising from the distribution of any
          information you choose to publicly post or share through our Website.
          Your profile is fully visible to all Members and customers of our
          Services. Subject to your settings, it can also be visible to others
          on or off of our Services (e.g., Visitors to our Services or users of
          third- party search engines). As detailed in our help center, your
          settings, degree of connection with the viewing Member, the
          subscriptions they may have, their usage of our services, access
          channels and search types (e.g., by name or by keyword) impact the
          availability of your profile and whether they can view certain fields
          in your profile. Posts, Likes, Follows, Comments, Messages Our
          Services allow viewing and sharing information including through
          posts, likes, follows and comments. When you share an article or a
          post (e.g., an update, image, video or article) publicly it can be
          viewed by everyone and re-shared anywhere (subject to your settings).
          Members, Visitors and others will be able to find and see your
          publicly-shared content, including your name (and photo if you have
          provided one). In a discussion, posts are visible to others in the
          group. Your membership in groups is public and part of your profile,
          but you can change visibility in your settings. Any information you
          share through companies’ or other organizations’ pages on our Services
          will be viewable by it and others who visit those pages. When you
          follow a person or organization, you are visible to others and that
          “page owner” as a follower. We let senders know when you act on their
          message, subject to your settings where applicable. Subject to your
          settings, we let a Member know when you view their profile. When you
          like or re-share or comment on another’s content (including ads),
          others will be able to view these “social actions” and associate it
          with you (e.g., your name, profile and photo if you provided it). Your
          employer can see how you use Services they provided for your work
          (e.g. as a recruiter or sales agent) and related information. We will
          not show them your job searches or personal messages. Communication
          Archival Regulated Members may need to store communications outside of
          our Service. Some Members (or their employers) need, for legal or
          professional compliance, to archive their communications and social
          media activity, and will use services of others to provide these
          archival services. We enable archiving of messages by those Members
          outside of our Services. For example, a financial advisor needs to
          archive communications with her clients through our Services in order
          to maintain her professional financial advisor license. Others’
          Services You may link your account with others’ services so that they
          can look up your contacts’ profiles, post your shares on such
          platforms, or start conversations with your connections on such
          platforms. Excerpts from your profile will also appear on the services
          of others. Subject to your settings, other services may look-up your
          profile. When you opt to link your account with other services,
          personal data will become available to them. The sharing and use of
          that personal data will be described in, or linked to, a consent
          screen when you opt to link the accounts. For example, you may link
          your Twitter or WeChat account to share content from our Services into
          these other services, or your email provider may give you the option
          to upload your Spark contacts into its own service. You may revoke the
          link with such accounts. Subject to your settings, excerpts from your
          profile will appear on the services of others (e.g., search engine
          results, mail and calendar applications that show a user a “mini”
          Spark profile of the person they are meeting or messaging, social
          media aggregators, talent and lead managers). “Old” profile
          information remains on these services until they update their data
          cache with changes you made to your profile. I. CHILDREN The Website
          is a general audience site, and the features, programs, promotions and
          other aspects of our Website requiring the submission of personal data
          are not intended for anyone under 18 years of age.  If you are under
          18, you may not access, use or register on the Website. We do not
          knowingly collect personal data from individuals under 18. If you are
          a parent or guardian of an individual under 18 and believe he or she
          has disclosed personal data to us without your permission, please
          contact us at contact@spark-founders.com.  A parent or guardian of a
          child under the age of 18 may request deletion of such child’s
          personal data as well as prohibit the use thereof. J. SECURITY We
          take reasonable steps to protect against the loss, misuse and
          alteration and protect the confidentiality, integrity, availability,
          and resilience of the information under our control, including Secure
          Sockets Layer (SSL) technology to encrypt the transmission of
          non-public personally identifiable financial and transaction
          information over the Internet, provided you are using an SSL-enabled
          device. We also utilize other reasonable security measures such as
          firewalls, security patches, and anti-virus programs, to protect
          non-public personal data. We also take steps to ensure personal data
          is backed up and remains available in the event of a security
          incident. We periodically test, assess, and evaluate the effectiveness
          of our safeguards and security controls. Despite these efforts, please
          be advised that no security system or means of transmitting data over
          the Internet can be guaranteed to be entirely secure (including
          without limitation with respect to computer viruses, malicious
          software and hacker attacks), and we cannot and do not guarantee or
          warrant the security of any information you disclose or transmit to us
          via the Internet, and are not responsible for the theft, destruction,
          or inadvertent disclosure of your personal data. For your own privacy
          protection, we recommend that you do not include any non-public
          personal or sensitive personal data such as passwords, social security
          numbers, credit card details, or bank account information, in any
          emails that you send to us. We will not contact you by email or text
          message to ask for such information. K. OTHER WEBSITES/LINKS As a
          convenience, our Website may reference or provide links to third-party
          websites and/or services that we do not control or maintain. When you
          access these third-party websites or services, you leave our Website,
          and we are not responsible for, and do not control, the content,
          security, or privacy practices employed by any third-party websites
          and services. You access such third-party websites and services at
          your own risk. Be aware that those third-party websites collect
          information and operate according to their own privacy practices. We
          are not responsible for the privacy practices employed by any
          third-party website. We encourage you to note when you leave the
          Website and to read the privacy statements of all third-party websites
          to understand how they collect, use and disclose personal data before
          submitting any personal data to those websites. L. INTERNATIONAL USE
          AND DATA TRANSFERS Your personal data will be stored and processed in
          the United States. If you are using the Website or other Services from
          outside the United States, by your use of the Website or other
          Services you acknowledge that we will transfer your data to, and store
          your personal data in, the United States, which may have different
          data protection rules than in your country, and personal data may
          become accessible as permitted by law in the United States, including
          to law enforcement and/or national security authorities in the United
          States. For transfers of data out of the European Economic Area, we
          use standard contractual clauses, and, as appropriate, other
          applicable mechanisms. M. Legal Disclosures We may need to share your
          data when we believe it’s required by law or to help protect the
          rights and safety of you, us or others. It is possible that we will
          need to disclose information about you when required by law, subpoena,
          or other legal process or if we have a good faith belief that
          disclosure is reasonably necessary to (1) investigate, prevent, or
          take action regarding suspected or actual illegal activities or to
          assist government enforcement agencies; (2) enforce our agreements
          with you, (3) investigate and defend ourselves against any third-party
          claims or allegations, (4) protect the security or integrity of our
          Service (such as by sharing with companies facing similar threats); or
          (5) exercise or protect the rights and safety of Spark, our Members,
          personnel, or others. We attempt to notify Members about legal demands
          for their personal data when appropriate in our judgment, unless
          prohibited by law or court order or when the request is an emergency.
          We may dispute such demands when we believe, in our discretion, that
          the requests are overbroad, vague or lack proper authority, but we do
          not promise to challenge every demand. We may share your data when our
          business is sold to others, but it must continue to be used in
          accordance with this Privacy Policy. We can also share your personal
          data as part of a sale, merger or change in control, or in preparation
          for any of these events. Any other entity which buys us or part of our
          business will have the right to continue to use your data, but only in
          the manner set out in this Privacy Policy unless you agree otherwise.
          N. Lawful Bases for Processing We have lawful bases to collect, use
          and share data about you. You have choices about our use of your data.
          At any time, you can withdraw consent you have provided by going to
          settings. We will only collect and process personal data about you
          where we have lawful bases. Lawful bases include consent (where you
          have given consent), contract (where processing is necessary for the
          performance of a contract with you (e.g. to deliver the Spark Services
          you have requested)) and “legitimate interests”. Where we rely on your
          consent to process personal data, you have the right to withdraw or
          decline your consent at any time and where we rely on legitimate
          interests, you have the right to object. If you have any questions
          about the lawful bases upon which we collect and use your personal
          data. O. CHANGES TO THIS PRIVACY NOTICE This Privacy Notice replaces
          all previous disclosures we may have provided to you about our
          information practices with respect to the Website. We may change or
          update this Privacy Notice in the future. You can identify the date
          that this Privacy Notice was last updated by looking at the date at
          the top of this Privacy Notice. Any revisions to this Privacy Notice
          will take effect upon posting to the Website. We will notify you of
          any material changes to this Privacy Notice either by sending an email
          to the email address that you provided to us and/or by placing a
          notice on the homepage of the Website. We encourage you to regularly
          review the current version of this Privacy Notice on the Website. Your
          use or continued use of the Website following posted changes
          constitutes your acknowledgement of the revised Privacy Notice then in
          effect. P. CONTACT US If you have any questions, comments or concerns
          about this Privacy Notice, or if you would like to exercise the
          choices discussed above, please contact us (a) by email
          contact@spark-founders.com (b) by postal mail in the U.S. at 8 The
          Green 19321, Dover DE 19901, Kent, DE, U.S.A. If you are located in
          the European Economic Area, and you wish to raise a concern regarding
          our use of your personal data, you have the right to do so with our
          lead supervisory authority, the U.K. Information Commissioner’s Office
          (the “ICO”) at www.ico.org.uk , or your local supervisory authority.
          We would, however, appreciate the opportunity to deal with your
          concerns before you approach the ICO, so please contact us in the
          first instance. Consult our Cookie Notice for more information about
          analytics, as well as how to control and/or opt out of certain web
          tracking technologies, including cookies and certain analytics
          providers.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
