import React from "react";
import { Link } from "react-router-dom";

const DashboardFooter = () => {
  return (
    <div>
      <footer className="flex lg:justify-between justify-between lg:mt-[25px] py-5">
        <Link to={"/privacy-policy"}>Privacy Policy</Link>
        <p>Copyright © {new Date().getFullYear()} Spark</p>
      </footer>
    </div>
  );
};

export default DashboardFooter;
