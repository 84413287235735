import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/images/sparkLogo.png";

const NavbarHeader = ({ isLoggedIn }) => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const isSpecialRoute =
    location.pathname === "/about" || location.pathname === "/northstar";

  return (
    <nav
      className={`bg-transparent py-4 md:py-6 lg:py-6 w-full z-50 ${
        isMenuOpen ? "fixed" : "absolute"
      }`}
    >
      <div className="flex items-center justify-between px-5 md:px-12">
        <div>
          <Link to="/">
            <img src={Logo} alt="Spark Logo" className="w-32" />
          </Link>
        </div>

        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          data-collapse-toggle="navbar-default"
          type="button"
          className="bg-transparent inline-flex items-center z-10 p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          {isMenuOpen ? (
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              className={`w-5 h-5 ${
                isSpecialRoute ? "text-white" : "text-spark-500"
              }`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          )}
        </button>

        <div
          className={`w-full lg:block lg:w-auto ${
            isMenuOpen ? "block" : "hidden"
          } lg:relative absolute top-0 left-0`}
          id="navbar-default"
        >
          <ul
            className={`text-lg bg-white z-10 lg:bg-transparent font-normal items-center flex flex-col lg:gap-0 gap-4 lg:p-0 lg:rounded-lg lg:flex-row lg:space-x-8 lg:mt-0 lg:border-0
            ${
              isMenuOpen
                ? "w-screen h-screen justify-center items-center flex"
                : "w-full"
            }`}
          >
            <li className="">
              <Link
                to="/"
                className={`block py-2 px-3 ${
                  isSpecialRoute ? "lg:text-white" : "lg:text-spark-500"
                } text-[20px] text-spark-500 rounded hover:border-b-2 lg:mt-0 hover:border-white lg:p-0 dark:text-spark-500`}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className={`block py-2 px-3 ${
                  isSpecialRoute ? "lg:text-white" : "lg:text-spark-500"
                } text-xl text-spark-500 rounded hover:border-b-2 lg:mt-0 hover:border-white lg:p-0 dark:text-spark-500`}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/0to1"
                className={`block py-2 px-3 ${
                  isSpecialRoute ? "lg:text-white" : "lg:text-spark-500"
                } text-xl text-spark-500 rounded hover:border-b-2 lg:mt-0 hover:border-white lg:p-0 dark:text-spark-500`}
              >
                0 to 1
              </Link>
            </li>
            <li>
              <Link
                to="/northstar"
                className={`block py-2 px-3 ${
                  isSpecialRoute ? "lg:text-white" : "lg:text-spark-500"
                } text-xl text-spark-500 rounded hover:border-b-2 lg:mt-0 hover:border-white lg:p-0 dark:text-spark-500`}
              >
                Northstar
              </Link>
            </li>

            {!isLoggedIn ? (
              <>
                <li className="my-2">
                  <Link
                    to="/login"
                    className="bg-spark-500 text-lg hover:bg-[#ae55b9] rounded-3xl text-white border border-white py-2 px-10"
                  >
                    Login
                  </Link>
                </li>
                <li>
                  <Link
                    to="/register"
                    className="dark:text-white bg-spark-500 text-lg hover:bg-[#ae55b9] rounded-3xl text-white border border-white py-2 px-[1.8rem]"
                  >
                    Register
                  </Link>
                </li>
              </>
            ) : (
              <>
                {" "}
                <li className="my-2">
                  <Link
                    to="/dashboard"
                    className="dark:text-white bg-spark-500 text-lg hover:bg-[#ae55b9] rounded-3xl text-white border border-white py-2 px-10"
                  >
                    Go to Dasboard
                  </Link>
                </li>
              
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavbarHeader;
