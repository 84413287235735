import React, { useEffect, useState, useCallback } from "react";
import Talk from "talkjs";
import { Session, Inbox } from "@talkjs/react";
import { api } from "../../Services/api-service";
import { useLocation } from "react-router-dom";

const Chat = () => {
  const [talkLoaded, setTalkLoaded] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const loggedinUser = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const user = location.state;
  useEffect(() => {
    Talk.ready.then(() => setTalkLoaded(true));
  }, []);

  useEffect(() => {
    getUSerId();
  }, []);

  const getUSerId = async () => {
    try {
      const response = await api.get("users/talkjs/token");
      if (response && response.status === 200) {
        setCurrentUser(response.data.token);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const syncUser = useCallback(() => {
    return new Talk.User({
      id: loggedinUser?.userId,
      name: `${loggedinUser?.firstName}`,
      email: loggedinUser?.email,
      accessToken: currentUser,
    });
  }, [
    currentUser,
    loggedinUser?.email,
    loggedinUser?.firstName,
    loggedinUser?.userId,
  ]);

  const syncConversation = useCallback(
    (session) => {
      const conversation = session.getOrCreateConversation(
        Talk.oneOnOneId(loggedinUser?.userId, user?.userId)
      );

      const other = new Talk.User({
        id: user?.userId,
        name: `${user?.firstName}`,
        email: user?.email,
        photoUrl: user?.profilePicture?.publicUrl,
      });
      conversation.setParticipant(session.me);
      conversation.setParticipant(other);
      conversation.setAttributes({ subject: "" });

      return conversation;
    },
    [
      loggedinUser?.userId,
      user?.email,
      user?.firstName,
      user?.profilePicture.publicUrl,
      user?.userId,
    ]
  );

  return (
    <div className="h-screen">
      {!talkLoaded ? (
        <>
          <h5 className="text-center mt-3">Loading...</h5>
        </>
      ) : (
        <>
          <Session appId="8lEQBPg4" syncUser={syncUser}>
            <Inbox
              style={{
                height: "80vh",
                paddingTop: "40px",
                width: "100%",
              }}
              {...(user && { syncConversation: syncConversation })}
            />
          </Session>
        </>
      )}
    </div>
  );
};

export default Chat;
