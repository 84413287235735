import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../Services/api-service";

export const fetchAttributes = createAsyncThunk(
  "attributes/fetchAttributes",
  async (_, thunkAPI) => {
    try {
      const response = await api.get(
        "attributes/list?type=lookingFor&type=skill&type=interest&type=businessStage&type=role"
      );

      const attributes = response.data.reduce((acc, attribute) => {
        acc[attribute.type] = acc[attribute.type]
          ? [...acc[attribute.type], attribute.name]
          : [attribute.name];

        return acc;
      }, {});

      return attributes;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const attributeSlice = createSlice({
  name: "attributes",
  initialState: {
    lookingFor: [],
    skill: [],
    interest: [],
    businessStage: [],
    role: [],
    loading: false,
    error: null,
  },

  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAttributes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAttributes.fulfilled, (state, action) => {
        state.loading = false;
        state.lookingFor = action.payload.lookingFor || [];
        state.skill = action.payload.skill || [];
        state.interest = action.payload.interest || [];
        state.businessStage = action.payload.businessStage || [];
        state.role = action.payload.role || [];
      })
      .addCase(fetchAttributes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default attributeSlice.reducer;
