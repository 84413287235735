import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { api } from "../../Services/api-service";
import notificationSvc from "../../Services/notification";
import NavbarHeader from "../../components/NavbarHeader";
import Footer from "../../components/footer";
import { FaArrowRight } from "react-icons/fa6";

const ResetPassword = ({ isLoggedIn }) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      notificationSvc.error("Passwords do not match!");
      return;
    }

    try {
      const response = await api.post("users/reset-password", {
        token,
        password: formData.password,
      });

      if (response && (response.status === 200 || response.status === 201)) {
        notificationSvc.success("Password reset successfully.");
        navigate("/login");
      }
    } catch (error) {
      notificationSvc.error("Failed to reset password. Please try again.");
    }
  };

  useEffect(() => {
    if (isLoggedIn)
      navigate('/')
  }, [isLoggedIn, navigate])

  return (
    <>
      <div className="h-screen w-full overflow-x-hidden flex flex-col justify-between">
        <NavbarHeader />

        <div className="px-4 w-full flex flex-col gap-6 mt-6 md:mt-14">
          <div className="flex flex-col justify-center items-center mt-20">
            <h1 className="md:text-3xl text-2xl text-[#212121] font-[600]">
              Reset Your Password
            </h1>
            <p className="text-gray-500 text-center mt-2">
              Enter a new password below.
            </p>
          </div>

          <div className="flex justify-center mt-4">
            <form onSubmit={handleResetPassword}>
              <div className="mb-5 md:mt-0 mt-5">
                <input
                  type="password"
                  className="h-[48px] border md:w-[315px] w-full border-[#D4D6D8] text-gray-900 pl-[12px] rounded-lg"
                  required
                  placeholder="New Password"
                  value={formData.password}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      password: e.target.value,
                    })
                  }
                />
              </div>

              <div className="mb-5">
                <input
                  type="password"
                  className="h-[48px] md:w-[315px] w-full border border-[#D4D6D8] text-gray-900 pl-[12px] rounded-lg"
                  required
                  placeholder="Confirm"
                  value={formData.confirmPassword}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      confirmPassword: e.target.value,
                    })
                  }
                />
              </div>

              <button
                type="submit"
                className="mb-5 flex h-12 w-full rounded-lg bg-spark-500 lg:justify-around gap-4 justify-center lg:items-center text-white text-sm font-[500] p-3"
              >
                <p>Reset Password</p>
                <FaArrowRight className="text-2xl" />
              </button>
            </form>
          </div>
        </div>
        {/* Body end*/}

        <Footer />
      </div>
    </>
  );
};

export default ResetPassword;
