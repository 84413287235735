import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../Services/api-service";

export const fetchPortal = createAsyncThunk(
  "portal/fetchPortal",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/plans/portal");

      if (response.status === 200) {
        const { hasSubscription, url } = response.data;

        if (!hasSubscription && hasSubscription !== undefined) {
          return { navigateToUpgrade: true };
        } else {
          return { url };
        }
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Error fetching portal data"
      );
    }
  }
);

const portalSlice = createSlice({
  name: "portal",
  initialState: {
    loading: false,
    error: null,
    url: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPortal.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPortal.fulfilled, (state, action) => {
        state.loading = false;
        state.url = action.payload?.url;
      })
      .addCase(fetchPortal.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default portalSlice.reducer;
