import React, { useEffect } from "react";
import sparkLogo from "../assets/images/sparkLogo.png";
import defaultImage from "../assets/images/default.png";
import { Link, useLocation } from "react-router-dom";
import { deleteCookie } from "../utils/cookies.ts"

import { useSelector } from "react-redux";
const Navbar = ({ firstName, displaypicture, }) => {
  const location = useLocation();
  const portalUrl = useSelector((state) => state.portal.url);

  useEffect(() => {
    if (portalUrl) {
      window.location.href = portalUrl;
    }
  }, [portalUrl]);


  return (
    <>
      <header className="h-full md:h-[84px] bg-white">
        <nav className="px-[20px] md:px-[100px]  py-[23px] mb-7 lg:mb-0">
          <div className="flex flex-wrap justify-between items-center ">
            {/* left */}
            <div class="grid grid-cols-2 items-end">
              <Link to={"/"}>
                <img src={sparkLogo} alt="Spark Logo" className="w-32" />
              </Link>
              <span className="mx-3 my-2 text-lg text-spark-500  font-[400]">Beta</span>
            </div>

            <div className="flex items-center lg:order-2 gap-[12px] w-fit lg:w-[598px] justify-end">
              <div className="hs-dropdown relative inline-flex">
                <button
                  id="hs-dropdown-custom-trigger"
                  type="button"
                  className="hs-dropdown-toggle py-1 ps-1 pe-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                >
                  <img
                    className="w-8 h-8 rounded-full"
                    src={displaypicture || defaultImage}
                    alt={firstName}
                  />
                  <span className="text-gray-600 font-medium truncate max-w-[7.5rem]">
                    {firstName}
                  </span>
                  <svg
                    className="hs-dropdown-open:rotate-180 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m6 9 6 6 6-6" />
                  </svg>
                </button>

                <div
                  className="hs-dropdown-menu transition-[opacity,margin] absolute z-[99999999] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg p-2 mt-2"
                  aria-labelledby="hs-dropdown-custom-trigger"
                >
                  {location.pathname !== "/profile-setup" && (
                    <>
                      <div className="font-bold flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 focus:outline-none focus:bg-gray-100">
                        <span className="flex w-3 h-3 me-1 bg-green-500 rounded-full"></span>
                        Free Premium Beta
                      </div>
                      {location.pathname !== '/' && (
                        <>
                          <Link
                            className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-base text-spark-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 "
                            to="/"
                          >
                            Dashboard
                          </Link>
                        </>
                      )}
                      <Link
                        className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-base text-spark-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 "
                        to="/chat"
                      >
                        Chat
                      </Link>
                      <Link
                        className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-base text-spark-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 "
                        to="/user-profile/update"
                      >
                        Update Profile
                      </Link>
                      <Link
                        className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-base text-spark-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                        to="https://spark-founders.c.paperos.com/c/0/workbench/2/205/0/new"
                      >
                        Co-founder Data Room
                      </Link>

                    </>
                  )}
                  <button
                    onClick={() => {
                      deleteCookie("loggedIn");
                      localStorage.clear();
                      window.location.href = "/";
                    }}
                    className="flex mt-3 items-center w-full text-white justify-center bg-spark-500 gap-x-3.5 py-2 px-3 rounded-lg text-sm hover:bg-spark-500 focus:outline-none focus:bg-gray-100"
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
