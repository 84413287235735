import React from "react";
import NavbarHeader from "../../components/NavbarHeader.js";
import { BackgroundGradientAnimation } from "../../components/ui/background-gradient-animation.tsx";
import Footer from "../../components/footer.js";

function About() {
  return (
    <>
      <div className="w-[100vw] overflow-x-hidden bg-white">
        <NavbarHeader />

        <BackgroundGradientAnimation>
          <div className="absolute inset-0 flex flex-col items-center justify-center text-white font-bold px-4 pointer-events-none text-3xl text-center md:text-4xl lg:text-7xl">
            <p className="bg-clip-text md:text-3xl text-xl xl:text-5xl text-transparent drop-shadow-2xl bg-gradient-to-b from-white/80 to-white/80 w-[80%] lg:w-[50%]">
              Connecting entrepreneurs Building successful ventures All you need
              to succeed
            </p>
          </div>
        </BackgroundGradientAnimation>
      </div>

      <div className="px-[10%] bg-spark-500 md:m-14 my-14 py-14">
        <div className="heading mb-5 text-[54px] font-semibold text-white">
          Why us…
        </div>

        <div className="grid md:grid-cols-3 gap-4">
          <div className="bg-white rounded-2xl py-5 px-8">
            <h2 className="heading mb-5 text-xl font-extrabold text-spark-500">
              Matching
            </h2>
            <p className="heading mb-5 text-lg font-bold text-spark-500">
              Some of us have ideas, some of us have expertise and some of us
              have the funds.{" "}
            </p>
            <p className="heading mb-5 text-lg  text-spark-500">
              Lets sit you at the right table. Have an initial talk.
            </p>
          </div>

          <div className="bg-white rounded-2xl py-5 px-8">
            <h2 className="heading mb-5 text-xl font-extrabold text-spark-500">
              Trust
            </h2>
            <p className="heading mb-5 text-lg font-bold text-spark-500">
              From an initial NDA to a partnership agreement, let us help you
              with all your legal needs.
            </p>
            <p className="heading mb-5 text-lg text-spark-500">
              We got your back. No bad surprises.
            </p>
          </div>

          <div className="bg-white rounded-2xl py-5 px-8">
            <h2 className="heading mb-5 text-xl font-extrabold text-spark-500">
              Negotiation
            </h2>
            <p className="heading mb-5 text-[18px] font-bold text-spark-500">
              Allow our experienced team help you create your equity agreement.
            </p>
            <p className="heading mb-5 text-lg text-spark-500">
              If you are happy we are happy. Everyone wins.
            </p>
          </div>
        </div>
      </div>

      <div className="mt-[200px]">
        <Footer marginTop="200" />
      </div>
    </>
  );
}

export default About;
