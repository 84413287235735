import React, { useEffect, useState } from "react";
import Select from "react-select";
import { api } from "../../Services/api-service";
import notificationSvc from "../../Services/notification";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import authSvc from "../../Services/auth-service";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchAttributes } from "../../redux/attributeSlice";

const Formdiv = ({ step, handleSave, handleBack, isLastStep }) => {
  const dispatch = useDispatch();

  const { lookingFor, skill, interest, role } = useSelector(
    (state) => state.attributes || {}
  );

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);

  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreview(reader.result);
      };

      reader.readAsDataURL(selectedFile);
    } else {
      setPreview(null);
    }
  }, [selectedFile]);

  const [formData, setFormData] = useState({
    personalDetails: {
      selectedPronoun: "",
      country: "",
      state: "",
      city: "",
      tagline: "",
    },
    profile: {
      role: "",
      lookingFor: "",
      businessStage: null,
      skills: [],
      interests: [],
    },
  });
  const navigate = useNavigate();

  const pronounOptions = [
    { value: "She/Her", label: "She/Her" },
    { value: "He/Him", label: "He/Him" },
    { value: "They/Them", label: "They/Them" },
  ];

  const unlockProfile = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append(
        "personalDetails[selectedPronoun]",
        formData.personalDetails.selectedPronoun
      );
      formDataToSend.append(
        "personalDetails[city]",
        formData.personalDetails.city
      );
      formDataToSend.append(
        "personalDetails[tagline]",
        formData.personalDetails.tagline
      );
      formDataToSend.append(
        "personalDetails[state]",
        formData.personalDetails.state
      );
      formDataToSend.append(
        "personalDetails[country]",
        formData.personalDetails.country
      );
      if (selectedFile) {
        formDataToSend.append("profileImage", selectedFile);
      }
      formDataToSend.append("profile[role]", formData.profile.role);
      formDataToSend.append("profile[lookingFor]", formData.profile.lookingFor);
      formDataToSend.append(
        "profile[businessStage]",
        formData.profile.businessStage
      );

      formData.profile.skills.forEach((skill) => {
        formDataToSend.append("profile[skills][]", skill);
      });

      formData.profile.interests.forEach((interest) => {
        formDataToSend.append("profile[interests][]", interest);
      });

      const response = await api.post(
        "users/profiles/create",
        formDataToSend,

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response && response.status === 200) {
        notificationSvc.success("Profile Created Successfully");
        await authSvc.refresh();
        localStorage.setItem("profileCreated", true);
        navigate("/");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const validateStep = () => {
    switch (step) {
      case 2:
        if (
          !formData.personalDetails.selectedPronoun ||
          !formData.personalDetails.city ||
          !formData.personalDetails.tagline
        ) {
          notificationSvc.error(
            "Please complete all personal information fields."
          );
          return false;
        }
        break;
      case 3:
        if (!selectedFile) {
          notificationSvc.error("Please upload a profile picture.");
          return false;
        }
        break;
      case 4:
        if (!formData.profile.role) {
          notificationSvc.error("Please select your role.");
          return false;
        }
        break;
      case 5:
        if (!formData.profile.lookingFor) {
          notificationSvc.error("Please select what you are looking for.");
          return false;
        }
        break;
      case 6:
        if (selectedSkills.length === 0) {
          notificationSvc.error("Please select at least one skill.");
          return false;
        }
        break;
      case 7:
        if (selectedInterests.length === 0) {
          notificationSvc.error("Please select at least one interest.");
          return false;
        }
        break;
      default:
        break;
    }
    return true;
  };

  const handleContinue = () => {
    if (validateStep()) {
      if (isLastStep) {
        unlockProfile();
      } else {
        handleSave();
      }
    }
  };

  const toggleSkills = (option) => {
    let updatedSkills;
    if (selectedSkills.includes(option)) {
      updatedSkills = selectedSkills.filter((item) => item !== option);
    } else {
      if (selectedSkills.length < 2) {
        updatedSkills = [...selectedSkills, option];
      } else {
        notificationSvc.error("You can select maximum 2 skills.");
        return;
      }
    }
    setSelectedSkills(updatedSkills);
    setFormData((prevData) => ({
      ...prevData,
      profile: {
        ...prevData.profile,
        skills: updatedSkills,
      },
    }));
  };

  useEffect(() => {
    dispatch(fetchAttributes());
  }, [dispatch]);

  const toggleInterest = (option) => {
    let updatedInterests;
    if (selectedInterests.includes(option)) {
      updatedInterests = selectedInterests.filter((item) => item !== option);
    } else {
      if (selectedInterests.length < 3) {
        updatedInterests = [...selectedInterests, option];
      } else {
        notificationSvc.error("You can select maximum 3 Interests.");
        return;
      }
    }
    setSelectedInterests(updatedInterests);
    setFormData((prevData) => ({
      ...prevData,
      profile: {
        ...prevData.profile,
        interests: updatedInterests,
      },
    }));
  };

  return (
    <div className="w-[100%] lg:w-3/4 h-auto rounded-[12px] py-[40px] md:px-[39px] bg-white">
      {step === 2 ? (
        <>
          <div className="">
            <h1 className="text-[26px] text-[#000000] font-[600] mb-4">
              Personal Information
            </h1>
            <p className="text-[16px] text-[#828282] font-[400] leading-[30px] mb-5">
              Tell us more about you
            </p>
          </div>
          <form>
            <div className="flex justify-between md:flex-row flex-col gap-2">
              <div className="mb-5 w-full md:w-[47%]">
                <Select
                  options={pronounOptions}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      personalDetails: {
                        ...formData.personalDetails,
                        selectedPronoun: e.value,
                      },
                    });
                  }}
                />
              </div>
              <CountrySelect
                onChange={(e) => {
                  setCountryid(e.id);
                  setFormData({
                    ...formData,
                    personalDetails: {
                      ...formData.personalDetails,
                      country: e.name,
                    },
                  });
                }}
                className="text-black"
                style={{ border: "0", height: "27px" }}
                placeHolder="Select Country"
              />
              <StateSelect
                countryid={countryid}
                onChange={(e) => {
                  setstateid(e.id);
                  setFormData({
                    ...formData,
                    personalDetails: {
                      ...formData.personalDetails,
                      state: e.name,
                    },
                  });
                }}
                style={{ border: "0", height: "27px" }}
                placeHolder="Select State"
              />

              <div className="mb-5 w-full md:w-[47%]">
                <CitySelect
                  countryid={countryid}
                  stateid={stateid}
                  style={{ border: "0", height: "27px" }}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      personalDetails: {
                        ...formData.personalDetails,
                        city: e.name,
                      },
                    });
                  }}
                  placeHolder="Select City"
                />
              </div>
            </div>
            <div>
              <div className="mb-5">
                <input
                  type="text"
                  className="h-[48px] border w-full border-[#D4D6D8] text-gray-900 pl-[12px] rounded-lg placeholder:w-full"
                  placeholder="Tagline that describes you best ?"
                  value={formData.personalDetails.tagline}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      personalDetails: {
                        ...formData.personalDetails,
                        tagline: e.target.value,
                      },
                    });
                  }}
                />
              </div>
            </div>
          </form>
        </>
      ) : null}

      {step === 3 ? (
        <>
          <div className="">
            <h1 className="text-[26px] text-[#000000] font-[600] leading-[31.47px] mb-4">
              Profile Picture
            </h1>
            <p className="text-[16px] text-[#828282] font-[400] leading-[30px] mb-5">
              Upload your picture
            </p>
          </div>
          <form className="flex gap-3 md:flex-row flex-col-reverse">
            <div className="flex items-center justify-center md:w-3/4 w-full">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold text-[#486284]">
                      Click to upload file
                    </span>
                    &nbsp; or drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    File format: .PNG, .JPG, .GIF up to 4MB
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                  }}
                  accept="image/*"
                />
              </label>
            </div>
            <div className="md:w-1/4 w-full h-64 border-2 border-gray-200 bg-slate-200/50">
              {preview && (
                <img
                  src={preview}
                  alt=""
                  className="w-full h-full object-cover"
                />
              )}
            </div>
          </form>
        </>
      ) : null}

      {step === 4 ? (
        <>
          <div className="">
            <h1 className="text-[26px] text-[#000000] font-[600] leading-[31.47px] mb-4">
              Your Role
            </h1>
            <p className="text-[16px] text-[#828282] font-[400] leading-[30px] mb-5">
              What do you bring? (Choose 1)
            </p>
          </div>
          {role?.map((role) => (
            <div key={role}>
              <button
                className={`w-full rounded-lg border bg-transparent text-black border-#9817A8 lg:justify-around justify-center lg:items-center text-[14px] text-start font-[500] leading-[30px] py-[10px] px-[16px] mt-[6px] ${formData.profile.role === role
                  ? "!bg-spark-500 text-white"
                  : "bg-white"
                  }`}
                onClick={() => {
                  setFormData((prevData) => ({
                    ...prevData,
                    profile: {
                      ...prevData.profile,
                      role: role,
                    },
                  }));
                }}
              >
                {role}
              </button>
            </div>
          ))}
        </>
      ) : null}

      {step === 5 ? (
        <>
          <div className="">
            <h1 className="text-[26px] text-[#000000] font-[600] leading-[31.47px] mb-4">
              Looking for
            </h1>
            <p className="text-[16px] text-[#828282] font-[400] leading-[30px] mb-5">
              What do you need? (Choose 1)
            </p>
          </div>
          {lookingFor.map((lookingFor) => (
            <div key={lookingFor}>
              <button
                className={`w-full rounded-lg border bg-transparent text-black border-#9817A8 lg:justify-around justify-center lg:items-center text-[14px] text-start font-[500] leading-[30px] py-[10px] px-[16px] mt-[6px] ${formData.profile.lookingFor === lookingFor
                  ? "!bg-spark-500 text-white"
                  : "bg-white"
                  }`}
                onClick={() => {
                  setFormData((prevData) => ({
                    ...prevData,
                    profile: {
                      ...prevData.profile,
                      lookingFor: lookingFor,
                    },
                  }));
                }}
              >
                {lookingFor}
              </button>
            </div>
          ))}
        </>
      ) : null}

      {step === 6 ? (
        <>
          <div className="">
            <h1 className="text-[26px] text-[#000000] font-[600] leading-[31.47px] mb-4">
              Your Skills
            </h1>
            <p className="text-[16px] text-[#828282] font-[400] leading-[30px] mb-5">
              What are you best at? (Choose up to 2)
            </p>
          </div>
          {skill.map((skill) => (
            <div key={skill}>
              <button
                className={`w-full rounded-lg border bg-transparent text-black border-#9817A8 lg:justify-around justify-center lg:items-center text-[14px] text-start font-[500] leading-[30px] py-[10px] px-[16px] mt-[6px] ${selectedSkills.includes(skill)
                  ? "!bg-spark-500 text-white"
                  : "bg-white"
                  }`}
                onClick={() => {
                  toggleSkills(skill);
                }}
              >
                {skill}
              </button>
            </div>
          ))}
        </>
      ) : null}

      {step === 7 ? (
        <>
          <div className="">
            <h1 className="text-[26px] text-[#000000] font-[600] leading-[31.47px] mb-4">
              Your Interests
            </h1>
            <p className="text-[16px] text-[#828282] font-[400] leading-[30px] mb-5">
              Which industries are you interested in? (Choose up to 3)
            </p>
          </div>
          {interest.map((interest) => (
            <div key={interest}>
              <button
                className={`w-full rounded-lg border bg-transparent text-black border-#9817A8 lg:justify-around justify-center lg:items-center text-[14px] text-start font-[500] leading-[30px] py-[10px] px-[16px] mt-[6px] ${selectedInterests.includes(interest)
                  ? "!bg-spark-500 text-white"
                  : "bg-white"
                  }`}
                onClick={() => {
                  toggleInterest(interest);
                }}
              >
                {interest}
              </button>
            </div>
          ))}
        </>
      ) : null}

      <div className=" mt-[76px] flex justify-between md:flex-row flex-col md:gap-0 gap-2 rounded-sm ">
        <div>
          <button
            onClick={() => {
              handleBack();
            }}
            className="flex h-[48px] md:w-[85px] w-full rounded-lg  lg:justify-around justify-center lg:items-center text-black border border-[#D6D6D6] text-[14px]  font-[500] leading-[30px] py-[10px] px-[16px]"
          >
            Back
          </button>
        </div>
        <div className="flex gap-2 md:flex-row flex-col">
          <button
            className=" flex md:w-[142px] h-[44px] w-full rounded-lg bg-spark-500 lg:justify-around justify-center gap-2 items-center text-white text-[14px]  font-[500] leading-[30px] py-[10px] px-[16px]"
            onClick={handleContinue}
          >
            {isLastStep ? "Submit" : "Continue"}

            <FaArrowRight className="text-2xl" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Formdiv;
