import { jwtDecode } from "jwt-decode";
import { BehaviorSubject } from "rxjs";
import notificationSvc from "./notification";
import spinnerSvc from "./spinner.js";
import axios from "axios";
import { api } from "./api-service.js";
import { API_BASE_URL } from "../utils/constans"
import { setCookie, deleteCookie } from "../utils/cookies.ts"


export const authApi = axios.create({
  baseURL: API_BASE_URL,
  headers: { Accept: "application/json" },
});

authApi.interceptors.request.use((config) => {
  spinnerSvc.start();
  if (config.url.includes("password")) {
    const token = localStorage.getItem("idToken") || "";
    if (token !== "") {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
});

authApi.interceptors.response.use(
  (response) => {
    spinnerSvc.stop();
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      notificationSvc.error(error.response.data.error.errors[0]);
      spinnerSvc.stop();
      return null;
    } else {
      spinnerSvc.stop();

      return null;
    }
  }
);

class AuthService {
  isValidating$ = new BehaviorSubject(false);
  loginBehavior = new BehaviorSubject(true);
  lastRefreshTime = new Date();
  loginObservable$;
  constructor() {
    this.loginObservable$ = this.loginBehavior.asObservable();
  }

  async checkProfile() {
    try {
      const response = await api.get("users/view");

      if (response && response.status === 200) {
        return response.data;
      }
    } catch (error) {
      notificationSvc.error(error);
    }
  }

  async login(email, password, authData = null) {
    try {
      let response;

      if (!authData)
        response = await authApi.post("users/signin", { email, password });
      else
        response = authData;

      if (response?.status === 200) {
        const tokenBase64 = response.data.token;

        const tokenData = jwtDecode(tokenBase64);

        localStorage.setItem("user", JSON.stringify(tokenData));

        this.storeTokens(response.data);

        const responses = await this.checkProfile();

        const profileCreated = responses.user.profile.id ? true : false;

        localStorage.setItem("profileCreated", profileCreated.toString());

        this.lastRefreshTime = new Date();

        if (profileCreated) {
          return { isLoggedIn: true, profileCreated: true };
        }

        return { isLoggedIn: true, profileCreated: false };
      } else {
        return { isLoggedIn: false, profileCreated: false };
      }
    } catch (err) {
      notificationSvc.error(err);

      console.log("Error", err);

      return { isLoggedIn: false, profileCreated: false };
    }
  }

  logout() {
    deleteCookie("loggedIn");
    localStorage.clear();
    window.location.href = '/login';
    notificationSvc.error('User not logged in');
    this.loginBehavior.next(false);
  }

  async refresh() {
    const refreshToken = localStorage.getItem("refreshToken");
    try {
      const { data, status } = await authApi.post("users/token", {
        refreshToken,
      });

      if (status === 200) {
        const tokenBase64 = data.token;
        const tokenData = jwtDecode(tokenBase64);
        localStorage.setItem("user", JSON.stringify(tokenData));
        const currentRefreshToken = localStorage.getItem("refreshToken");
        this.storeTokens({
          token: data.token,
          refreshToken: currentRefreshToken,
        });
        this.lastRefreshTime = new Date();
        return true;
      } else {
        this.logout();
        return false;
      }
    } catch (err) {
      this.logout();
      return false;
    }
  }

  storeTokens(tokens) {
    const { refreshToken, token } = tokens;
    localStorage.setItem("idToken", token);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("loggedIn", true);
    setCookie('loggedIn', 'true', 7);
  }

  async validate() {
    this.isValidating$.next(true);
    const response = await this.refresh();
    this.isValidating$.next(false);
    return { isLoggedIn: true, response };
  }
}

const authSvc = new AuthService();
export default authSvc;
