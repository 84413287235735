import React from 'react'

const Success = () => {
  return (
    <div className='text-center'>
      <div>Welcome to the world of Spark Premium - Where Cofounders Meet</div>
      <div>Unlimited Matches + Legally Binding Relationships</div>
    </div>
  )
}

export default Success