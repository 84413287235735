import React from 'react';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { api } from '../../Services/api-service';
import authSvc from '../../Services/auth-service';
import notificationSvc from '../../Services/notification';
import { LINKEDIN_CLIENT_ID } from '../../utils/constans';

const LinkedinOAuth = () => {
    const { linkedInLogin } = useLinkedIn({
        scope: 'openid email profile',
        clientId: LINKEDIN_CLIENT_ID,
        redirectUri: `${window.location.origin}/linkedin`,
        onSuccess: async (code) => {
            const auth = await api.post('/auth/linkedIn', {
                code
            });

            if (!auth.data?.email)
                return
            notificationSvc.info(`Logged in as ${auth.data?.email}`)
            await authSvc.login(null, null, auth)
            setTimeout(() => {
                window.location.href = '/'
            }, 2500);
        },

        onError: (error) => {
            console.log(error);
            notificationSvc.error('Failed to login with LinkedIn. ' + error.errorMessage || error.error_description)
        },
    });
    return (
        <button
            onClick={linkedInLogin}
            className='inline-flex h-[48px] border md:w-[315px] w-full items-center justify-center gap-2 rounded-lg border border-slate-300 bg-white p-2 text-sm font-medium text-black outline-none focus:ring-2 focus:ring-spark-500 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-60'
        >
            <img src='https://www.svgrepo.com/show/448234/linkedin.svg' alt='Google' className='h-[18px] w-[18px] ' />
            Continue with LinkedIn
        </button>
    );
};

export default LinkedinOAuth;
