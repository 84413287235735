function getCookieExpiryDate(days: number) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    return date.toUTCString();
}

// Helper para crear una cookie
function setCookie(name: string, value: boolean, days: number) {
    const expires = `expires=${getCookieExpiryDate(days)}`;
    document.cookie = `${name}=${value}; ${expires}; path=/; domain=.spark-founders.com; SameSite=None; Secure`;
}

function deleteCookie(name: string) {
    document.cookie = `${name}=; path=/; domain=.spark-founders.com; SameSite=None; Secure`;
}

export { setCookie, deleteCookie }
