import React, { useEffect, useState } from "react";
import Cards from "../../components/cards";
import { api } from "../../Services/api-service";
import { useDispatch, useSelector } from "react-redux";
import { fetchActivePlan } from "../../redux/activePlanSlice";
import DashboardFooter from "../../components/DashboardFooter";
import Select from "react-select";
import { fetchAttributes } from "../../redux/attributeSlice";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import {
  ExpansionPanel
} from "@react-md/expansion-panel";


const Dashboard = () => {
  const [matches, setMatches] = useState(null);

  const [loading, setLoading] = useState(true);

  const [filters, setFilters] = useState({
    country: "",
    state: "",
    city: "",
    interests: null,
    skills: null,
    scoreRange: null,
  });

  const dispatch = useDispatch();
  const activeplan = useSelector((state) => state.activePlan.activeplan);
  const portalUrl = useSelector((state) => state.portal.url);
  const { skill, interest } = useSelector((state) => state.attributes);

  useEffect(() => {
    dispatch(fetchAttributes());
  }, [dispatch]);

  const profile = async (currentFilters = filters) => {
    let queryParams = new URLSearchParams();

    if (currentFilters.country) {
      queryParams.append("country", currentFilters.country.toString());
    }

    if (currentFilters.state) {
      queryParams.append("state", currentFilters.state.toString());
    }

    if (currentFilters.city) {
      queryParams.append("city", currentFilters.city.toString());
    }
    if (currentFilters.interests && currentFilters.interests.length > 0) {
      const interests = currentFilters.interests
        .map((item) => item.value)
        .join(",");
      queryParams.append("interests", interests);
    }

    if (currentFilters.skills && currentFilters.skills.length > 0) {
      const skills = currentFilters.skills.map((item) => item.value).join(",");
      queryParams.append("skills", skills);
    }

    if (currentFilters.scoreRange && currentFilters.scoreRange.value) {
      queryParams.append(
        "fromScore",
        currentFilters.scoreRange.value.from.toString()
      );
      queryParams.append(
        "toScore",
        currentFilters.scoreRange.value.to.toString()
      );
    }

    const response = await api.get(
      `users/profiles/list?${queryParams.toString()}`
    );

    if (response && response.status === 200) {
      setMatches(response.data);
    } else {
      setMatches([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!activeplan) {
      dispatch(fetchActivePlan());
    }

    profile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeplan, dispatch]);

  useEffect(() => {
    if (portalUrl) {
      window.location.href = portalUrl;
    }
  }, [portalUrl]);

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;

    setFilters((prevState) => ({
      ...prevState,
      [name]: selectedOption,
    }));
  };

  const [countryid, setCountryid] = useState(0);

  const [stateid, setstateid] = useState(0);

  const resetFilters = () => {
    const newFilters = {
      country: "",
      state: "",
      city: "",
      interests: null,
      skills: null,
      scoreRange: null,
    };

    setFilters(newFilters);
    setCountryid(0);
    setstateid(0);
    profile(newFilters);
  };
  const scoreRangeOptions = [
    { value: { from: 0, to: 25 }, label: "0-25" },
    { value: { from: 25, to: 50 }, label: "25-50" },
    { value: { from: 50, to: 75 }, label: "50-75" },
    { value: { from: 75, to: 100 }, label: "75-100" },
  ];

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      height: "42px",
      minHeight: "42px",
    }),
  };

  const [expanded, setExpanded] = useState(false);

  return (
    <div className="px-[20px] lg:px-[100px]">
      <div className="min-h-[85vh] w-full">
        <p className="rounded-[41px] bg-spark-500 bg-opacity-10 p-[15px] mt-5 text-spark-500  text-[15px] font-[400]">
          This is Spark Beta! Enjoy all the premium features of Spark for free until <span className="font-bold">December 31st</span>
        </p>
        <ExpansionPanel className="mt-10"
          header="Filter"
          headerClassName="text-2xl font-bold flex items-center justify-between"
          onExpandClick={() => setExpanded(!expanded)}
          id="single-panel-own-props"
          expanded={expanded}
          expanderIcon={
            <svg
              className={`size-4 ${expanded ? 'rotate-180' : ''} transition ml-2`}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="m6 9 6 6 6-6" />
            </svg>
          }
        >
          <div className="mt-10">
            <hr />
            <div className="py-5">
              <div className="grid lg:grid-cols-3 gap-4 -z-50 mt-5">
                <CountrySelect
                  onChange={(e) => {
                    setCountryid(e.id);
                    setFilters((prev) => ({ ...prev, country: e.name }));
                  }}
                  style={{ border: "0" }}
                  placeHolder="Select Country"
                />
                <StateSelect
                  countryid={countryid}
                  onChange={(e) => {
                    setstateid(e.id);
                    setFilters((prev) => ({ ...prev, state: e.name }));
                  }}
                  style={{ border: "0" }}
                  placeHolder="Select State"
                />
                <CitySelect
                  countryid={countryid}
                  stateid={stateid}
                  style={{ border: "0" }}
                  onChange={(e) => {
                    setFilters((prev) => ({ ...prev, city: e.name }));
                  }}
                  placeHolder="Select City"
                />
                <Select
                  name="interests"
                  options={interest.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  value={filters.interests}
                  onChange={handleSelectChange}
                  placeholder="Select Interests"
                  styles={customSelectStyles}
                  isMulti={true}
                />
                <Select
                  name="skills"
                  options={skill.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  value={filters.skills}
                  onChange={handleSelectChange}
                  placeholder="Select Skills"
                  styles={customSelectStyles}
                  isMulti={true}
                />
                <Select
                  name="scoreRange"
                  options={scoreRangeOptions}
                  value={filters.scoreRange}
                  onChange={handleSelectChange}
                  placeholder="Select Score Range"
                  styles={customSelectStyles}
                />
              </div>
              <div className="flex mt-5 justify-end gap-2">
                <button
                  onClick={() => {
                    profile();
                  }}
                  className="bg-spark-500 text-white px-4 rounded"
                  style={{ height: "41px" }}
                >
                  Apply Filters
                </button>

                <button
                  onClick={() => {
                    resetFilters();
                  }}
                  style={{ height: "41px" }}
                  className="bg-white text-black border border-neutral-500 px-4 rounded"
                >
                  Reset Filters
                </button>
              </div>
            </div>
            <hr />
          </div>
        </ExpansionPanel>



        {!loading && (
          <>
            <h1 className="text-2xl font-bold py-5">Your Potential Matches</h1>
          </>
        )}

        <div className="grid lg:grid-cols-3 grid-cols-1 gap-[16px] mt-3">
          {loading ? (
            <div className="col-span-3 text-center mt-8">
              <h2>Looking for matches</h2>
            </div>
          ) : matches?.profiles?.length > 0 ? (
            matches?.profiles.map((profile) => (
              <div key={profile.id}>
                <Cards match={profile} activePlan={activeplan} />
              </div>
            ))
          ) : (
            <div className="col-span-3 text-center mt-8">
              <h2>No matches found</h2>
            </div>
          )}

        </div>
      </div>
      <DashboardFooter />
    </div>
  );
};

export default Dashboard;
