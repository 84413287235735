import React, { useEffect, useState } from "react";
import { api } from "../../Services/api-service";
import DashboardFooter from "../../components/DashboardFooter";
import notificationSvc from "../../Services/notification";
import { useNavigate } from "react-router-dom";
import authSvc from "../../Services/auth-service";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { useSelector, useDispatch } from "react-redux";
import { fetchAttributes } from "../../redux/attributeSlice";
import Select from "react-select";

const Update = () => {
  const [formData, setFormData] = useState({
    personalDetails: {
      firstName: "",
      lastName: "",
      email: "",
      tagline: "",
      country: null,
      state: null,
      city: "",
    },
    profile: {
      role: "",
      lookingFor: "",
      businessStage: "",
      skills: [],
      interests: [],
      description: "",
    },
  });

  const [selectedFile, setSelectedFile] = useState(null);

  const [preview, setPreview] = useState(null);

  const [countryid, setCountryid] = useState(0);

  const [stateid, setstateid] = useState(0);

  const dispatch = useDispatch();

  const { lookingFor, skill, interest, role } = useSelector(
    (state) => state.attributes || {}
  );

  const navigate = useNavigate();

  const viewProfile = async () => {
    const response = await api.get("/users/view");
    if (response && response.status === 200) {
      const user = response.data.user;

      setPreview(response.data.user.profilePicture.publicUrl);
      setFormData({
        personalDetails: {
          firstName: user.firstName || "",
          lastName: user.lastName || "",
          email: user.email || "",
          tagline: user.tagline || "",
          country: user.country || null,
          state: user.state || null,
          city: user.city || "",
        },
        profile: {
          role: user.profile.role || "",
          lookingFor: user.profile.lookingFor || "",
          businessStage: user.profile.businessStage || "",
          skills: user.profile.skills || [],
          interests: user.profile.interests || [],
          description: user.profile.description || "",
        },
      });

      setCountryid(user.country ? user.country.id : 0);
      setstateid(user.state ? user.state.id : 0);
    }
  };

  useEffect(() => {
    viewProfile();
  }, []);

  useEffect(() => {
    dispatch(fetchAttributes());
  }, [dispatch]);

  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreview(reader.result);
      };

      reader.readAsDataURL(selectedFile);
    } else {
      setPreview(null);
    }
  }, [selectedFile]);

  const updateProfile = async (e) => {
    e.preventDefault();
    const response = await api.put("users/profiles/update", formData);
    if (response && response.status === 200) {
      notificationSvc.success("Profile Updated Successfully.");
      navigate("/");

      authSvc.refresh();
    }
  };

  const deleteUser = async () => {
    const response = await api.delete("users/delete");

    if (response && response.status === 200) {
      localStorage.clear();
      window.location.reload();
      notificationSvc.success(response.data.message);
    }
  };

  const updateProfilePicture = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();

      if (selectedFile) {
        formData.append("profileImage", selectedFile);
      }
      const response = await api.patch("/users/profile-picture", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response && response.status === 200) {
        notificationSvc.success(response.data.message);
        authSvc.refresh();
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <div className="px-[20px] lg:px-[100px]">
      <form
        className="mt-10"
        onSubmit={(e) => {
          updateProfile(e);
        }}
      >
        <div className="space-y-12 sm:space-y-16">
          <div>
            <h2 className="text-lg font-semibold leading-7 text-gray-900">
              Update Profile
            </h2>
          </div>

          <div>
            <h2 className="text-base font-semibold text-gray-900">
              Personal Information
            </h2>

            <div className="">
              <p className="text-[16px] text-[#828282] font-[400] leading-[30px] mb-5">
                Update Profile picture
              </p>
            </div>
            <div className="flex justify-between">
              <div className="">
                <label
                  htmlFor="dropzone-file"
                  className="flex flex-col rounded-full items-center justify-center w-64 h-64 border-2 border-gray-300 border-dashed cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span className="font-semibold text-[#486284]">
                        Click to upload file
                      </span>
                      &nbsp; or drag and drop
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      File format: .PNG, .JPG, .GIF up to 4MB
                    </p>
                  </div>
                  <input
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    onChange={(e) => {
                      setSelectedFile(e.target.files[0]);
                    }}
                    accept="image/*"
                  />
                </label>
                <div className="mt-3 mx-auto">
                  <button
                    onClick={(e) => {
                      updateProfilePicture(e);
                    }}
                    className="bg-spark-500 w-full py-2 text-white rounded-lg"
                  >
                    Upload Image
                  </button>
                </div>
              </div>

              <div className="w-64 h-64 border-2 border-gray-200 bg-slate-200/50">
                {preview && (
                  <img
                    src={preview}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                )}
              </div>
            </div>

            <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  First name
                </label>
                <div className="mt-2  sm:col-span-2 sm:mt-0">
                  <input
                    type="text"
                    value={formData.personalDetails.firstName}
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        personalDetails: {
                          ...prev.personalDetails,
                          firstName: e.target.value,
                        },
                      }));
                    }}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Last name
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <input
                    type="text"
                    value={formData.personalDetails.lastName}
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        personalDetails: {
                          ...prev.personalDetails,
                          lastName: e.target.value,
                        },
                      }));
                    }}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Email address
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <input
                    type="email"
                    value={formData.personalDetails.email}
                    disabled
                    className="block disabled:bg-neutral-300 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="tagline"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Tagline
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <input
                    type="text"
                    value={formData.personalDetails.tagline}
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        personalDetails: {
                          ...prev.personalDetails,
                          tagline: e.target.value,
                        },
                      }));
                    }}
                    className="block disabled:bg-neutral-300 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Description
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <input
                    type="text"
                    value={formData.profile.description}
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        profile: {
                          ...prev.profile,
                          description: e.target.value,
                        },
                      }));
                    }}
                    className="block disabled:bg-neutral-300 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Country
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <CountrySelect
                    value={formData.personalDetails.country}
                    onChange={(country) => {
                      setCountryid(country.id);
                      setFormData((prev) => ({
                        ...prev,
                        personalDetails: {
                          ...prev.personalDetails,
                          country: country.name,
                          state: null,
                          city: "",
                        },
                      }));
                    }}
                    className="block disabled:bg-neutral-300 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"
                    placeHolder="Select Country"
                  />
                  <span className="mt-3">
                    {formData.personalDetails.country}
                  </span>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="state"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  State
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <StateSelect
                    value={formData.personalDetails.state}
                    countryid={countryid}
                    onChange={(state) => {
                      setstateid(state.id);
                      setFormData((prev) => ({
                        ...prev,
                        personalDetails: {
                          ...prev.personalDetails,
                          state: state.name,
                          city: "",
                        },
                      }));
                    }}
                    className="block disabled:bg-neutral-300 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"
                    placeHolder="Select State"
                  />
                  <span className="mt-3">{formData.personalDetails.state}</span>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  City
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <CitySelect
                    value={formData.personalDetails.city}
                    countryid={countryid}
                    stateid={stateid}
                    onChange={(city) => {
                      setFormData((prev) => ({
                        ...prev,
                        personalDetails: {
                          ...prev.personalDetails,
                          city: city.name,
                        },
                      }));
                    }}
                    className="block disabled:bg-neutral-300 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"
                    placeHolder="Select City"
                  />
                  <span className="mt-3">{formData.personalDetails.city}</span>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="role"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Role
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <Select
                    value={role
                      .map((role) => ({ value: role, label: role }))
                      .find((option) => option.value === formData.profile.role)}
                    options={role.map((roleItem) => ({
                      value: roleItem,
                      label: roleItem,
                    }))}
                    onChange={(selectedOption) => {
                      setFormData((prev) => ({
                        ...prev,
                        profile: {
                          ...prev.profile,
                          role: selectedOption.value,
                        },
                      }));
                    }}
                    placeholder="Select Role"
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="lookingFor"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Looking For
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <Select
                    value={lookingFor
                      .map((looking) => ({ value: looking, label: looking }))
                      .find(
                        (option) => option.value === formData.profile.lookingFor
                      )}
                    options={lookingFor.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    onChange={(selectedOption) => {
                      setFormData((prev) => ({
                        ...prev,
                        profile: {
                          ...prev.profile,
                          lookingFor: selectedOption.value,
                        },
                      }));
                    }}
                    placeholder="Select What You're Looking For"
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="skills"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Skills
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <Select
                    value={skill
                      .map((skillItem) => ({
                        value: skillItem,
                        label: skillItem,
                      }))
                      .filter((option) =>
                        formData.profile.skills.includes(option.value)
                      )}
                    options={skill.map((skillItem) => ({
                      value: skillItem,
                      label: skillItem,
                    }))}
                    onChange={(selectedOptions) => {
                      setFormData((prev) => ({
                        ...prev,
                        profile: {
                          ...prev.profile,
                          skills: selectedOptions.map((option) => option.value),
                        },
                      }));
                    }}
                    isMulti
                    placeholder="Select Skills"
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="interests"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Interests
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <Select
                    value={interest
                      .map((interestItem) => ({
                        value: interestItem,
                        label: interestItem,
                      }))
                      .filter((option) =>
                        formData.profile.interests.includes(option.value)
                      )}
                    options={interest.map((interestItem) => ({
                      value: interestItem,
                      label: interestItem,
                    }))}
                    onChange={(selectedOptions) => {
                      setFormData((prev) => ({
                        ...prev,
                        profile: {
                          ...prev.profile,
                          interests: selectedOptions.map(
                            (option) => option.value
                          ),
                        },
                      }));
                    }}
                    isMulti
                    placeholder="Select Interests"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-between gap-x-6">
          <button
            type="button"
            aria-haspopup="dialog"
            aria-expanded="false"
            aria-controls="hs-scale-animation-modal"
            data-hs-overlay="#hs-scale-animation-modal"
            className="inline-flex justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          >
            Delete User Account
          </button>
          <button
            type="submit"
            className="inline-flex justify-center rounded-md bg-spark-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-spark-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-spark-600"
          >
            Save
          </button>
        </div>
      </form>

      <DashboardFooter />
      <div
        id="hs-scale-animation-modal"
        className="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
        role="dialog"
        tabIndex="-1"
        aria-labelledby="hs-scale-animation-modal-label"
      >
        <div className="hs-overlay-animation-target hs-overlay-open:scale-100 hs-overlay-open:opacity-100 scale-95 opacity-0 ease-in-out transition-all duration-200 sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
          <div className="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto">
            <div className="flex justify-between items-center py-3 px-4 border-b">
              <h3
                id="hs-scale-animation-modal-label"
                className="font-bold text-gray-800"
              >
                Delete Account Confirmation
              </h3>
              <button
                type="button"
                className="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none"
                aria-label="Close"
                data-hs-overlay="#hs-scale-animation-modal"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>
            <div className="p-4 overflow-y-auto">
              <p className="mt-1 text-gray-800">
                All Data is going to be deleted.
              </p>
            </div>
            <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t">
              <button
                onClick={() => {
                  deleteUser();
                }}
                type="button"
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Update;
