import React from 'react';

import { LinkedInCallback } from 'react-linkedin-login-oauth2';
const LinkedInRedirect = () => {
    return (
        <LinkedInCallback />
    );
};

export default LinkedInRedirect;
