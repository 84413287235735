import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import Formdiv from "../../components/UnlockYourPotential/Formdiv";
import CheckList from "../../components/UnlockYourPotential/CheckList";

const UnlockPotential = () => {
  const [step, setStep] = useState(2);

  const handleSave = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    if (step > 2) {
      setStep(step - 1);
    }
  };

  const lastStep = 7;
  return (
    <div className="max-w[100vw] overflow-x-hidden">
      <div className="flex lg:flex-row flex-col m-auto min-h-[100vh] flex-wrap min-w-[100vw] py-[30px]">
        {isMobile ? (
          <>
            <div className="flex flex-wrap justify-between px-[10%] w-full">
              <CheckList handleSave={handleSave} step={step} />
              <Formdiv
                handleSave={handleSave}
                handleBack={handleBack}
                step={step}
                isLastStep={step === lastStep}
              />
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-wrap justify-between px-[10%] w-full">
              <Formdiv
                handleSave={handleSave}
                handleBack={handleBack}
                step={step}
                isLastStep={step === lastStep}
              />
              <CheckList handleSave={handleSave} step={step} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UnlockPotential;
