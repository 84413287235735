import React from "react";
import NavbarHeader from "../../components/NavbarHeader.js";
import Footer from "../../components/footer";
const TermsConditions = () => {
  return (
    <div className="">
      <NavbarHeader />
      <div className="pt-40 p-5 w-5/6 mx-auto">
        <h1 className="font-bold text-lg text-gray-600">
          Website Terms of Use
        </h1>
        <p className="text-gray-500 text-sm mt-3 text-justify">
          These terms of use are entered into by and between you and Spark
          Founders LLC (Spark) (”Company”, “we” or “us”). The following terms
          and conditions, together with any documents they expressly incorporate
          by reference (collectively, these “Terms of Use”), govern your access
          to and use of www.spark-founders.com including any content,
          functionality, and services offered on or through
          www.spark-founders.com ( “Website”), whether as a guest or a
          registered user
        </p>
        <p className="text-gray-500 text-sm mt-3 text-justify">
          Please read the Terms of Use carefully. By using the Website, you
          accept and agree to be bound and abide by these Terms of Use and our
          Privacy Policy, incorporated herein by reference. If you do not want
          to agree to these Terms of Use or the Privacy Policy, you must not
          access or use the Website.
        </p>
        <p className="text-gray-500 text-sm mt-3 text-justify">
          This Website is offered and available to users who 18 years of age or
          older. By using this Website, you represent and warrant that you are
          of legal age to form a binding contract with the Company and meet all
          of the foregoing eligibility requirements. If you do not meet all of
          these requirements, you must not access or use the Website.
        </p>
        <h3 className="font-bold mt-3 text-gray-600">
          Changes to the Terms of Use
        </h3>
        <p className="text-gray-500 text-sm mt-3 text-justify">
          We may revise and update these Terms of Use from time to time in our
          sole discretion. All changes are effective immediately when we post
          them and apply to all access to and use of the Website thereafter.
        </p>
        <p className="text-gray-500 text-sm mt-3 text-justify">
          Your continued use of the Website following the posting of revised
          Terms of Use means that you accept and agree to the changes. You are
          expected to check this page from time to time/ frequently/each time
          you access this Website, so you are aware of any changes, as they are
          binding on you.
        </p>
        <h3 className="font-bold mt-3 text-gray-600">
          Accessing the Website and Account Security.
        </h3>
        <p className="text-gray-500 text-sm mt-3 text-justify">
          We reserve the right to withdraw or amend this Website, and any
          service or material we provide on the Website, in our sole discretion
          without notice. We will not be liable if for any reason all or any
          part of the Website is unavailable at any time or for any period. From
          time to time, we may restrict access to some parts of the Website, or
          the entire Website, to users, including registered users.
        </p>

        <p className="text-gray-500 text-sm mt-3 text-justify">
          We reserve the right to withdraw or amend this Website, and any
          service or material we provide on the Website, in our sole discretion
          without notice. We will not be liable if for any reason all or any
          part of the Website is unavailable at any time or for any period. From
          time to time, we may restrict access to some parts of the Website, or
          the entire Website, to users, including registered users. You are
          responsible for: Making all arrangements necessary for you to have
          access to the Website. Ensuring that all persons who access the
          Website through your internet connection are aware of these Terms of
          Use and comply with them. To access the Website or some of the
          resources it offers, you may be asked to provide certain registration
          details or other information. It is a condition of your use of the
          Website that all the information you provide on the Website is
          correct, current, and complete. You agree that all information you
          provide to register with this Website or otherwise, including but not
          limited to through the use of any interactive features on the Website,
          is governed by our Privacy Policy, and you consent to all actions we
          take with respect to your information consistent with our Privacy
          Policy. If you choose, or are provided with, a user name, password, or
          any other piece of information as part of our security procedures, you
          must treat such information as confidential, and you must not disclose
          it to any other person or entity. You also acknowledge that your
          account is personal to you and agree not to provide any other person
          with access to this Website or portions of it using your user name,
          password, or other security information. You agree to notify us
          immediately of any unauthorized access to or use of your user name or
          password or any other breach of security. You also agree to ensure
          that you exit from your account at the end of each session. You should
          use particular caution when accessing your account from a public or
          shared computer so that others are not able to view or record your
          password or other personal information. We have the right to disable
          any user name, password, or other identifier, whether chosen by you or
          provided by us, at any time in our sole discretion for any or no
          reason, including if, in our opinion, you have violated any provision
          of these Terms of Use. Prohibited Uses You may use the Website only
          for lawful purposes and in accordance with these Terms of Use. You
          agree not to use the Website: In any way that violates any applicable
          federal, state, local, or international law or regulation (including,
          without limitation, any laws regarding the export of data or software
          to and from the US or other countries). For the purpose of exploiting,
          harming, or attempting to exploit or harm minors in any way by
          exposing them to inappropriate content, asking for personally
          identifiable information, or otherwise. To send, knowingly receive,
          upload, download, use, or re-use any material that does not comply
          with the Terms of Use. To transmit, or procure the sending of, any
          advertising or promotional material without our prior written consent,
          including any “junk mail”, “chain letter”, “spam”, or any other
          similar solicitation. To impersonate or attempt to impersonate the
          Company, a Company employee, another user, or any other person or
          entity (including, without limitation, by using email addresses
          associated with any of the foregoing). To engage in any other conduct
          that restricts or inhibits anyone’s use or enjoyment of the Website,
          or which, as determined by us, may harm the Company or users of the
          Website or expose them to liability. Additionally, you agree not to:
          Use the Website in any manner that could disable, overburden, damage,
          or impair the site or interfere with any other party’s use of the
          Website, including their ability to engage in real time activities
          through the Website. Use any robot, spider, or other automatic device,
          process, or means to access the Website for any purpose, including
          monitoring or copying any of the material on the Website. Use any
          manual process to monitor or copy any of the material on the Website
          or for any other unauthorized purpose without our prior written
          consent. Use any device, software, or routine that interferes with the
          proper working of the Website. Introduce any viruses, trojan horses,
          worms, logic bombs, or other material that is malicious or
          technologically harmful. Attempt to gain unauthorized access to,
          interfere with, damage, or disrupt any parts of the Website, the
          server on which the Website is stored, or any server, computer, or
          database connected to the Website. Attack the Website via a
          denial-of-service attack or a distributed denial-of-service attack.
          Otherwise attempt to interfere with the proper working of the Website.
          Content Standards These content standards apply to any and all User
          Contributions and use of Interactive Services. User Contributions must
          in their entirety comply with all applicable federal, state, local,
          and international laws and regulations. Without limiting the
          foregoing, User Contributions must not: Contain any material that is
          defamatory, obscene, indecent, abusive, offensive, harassing, violent,
          hateful, inflammatory, or otherwise objectionable. Promote sexually
          explicit or pornographic material, violence, or discrimination based
          on race, sex, religion, nationality, disability, sexual orientation,
          or age. Infringe any patent, trademark, trade secret, copyright, or
          other intellectual property or other rights of any other person.
          Violate the legal rights (including the rights of publicity and
          privacy) of others or contain any material that could give rise to any
          civil or criminal liability under applicable laws or regulations or
          that otherwise may be in conflict with these Terms of Use and our
          Privacy Policy. Be likely to deceive any person. Promote any illegal
          activity, or advocate, promote, or assist any unlawful act. Cause
          annoyance, inconvenience, or needless anxiety or be likely to upset,
          embarrass, alarm, or annoy any other person. Impersonate any person,
          or misrepresent your identity or affiliation with any person or
          organization. Involve commercial activities or sales, such as
          contests, sweepstakes and other sales promotions, barter, or
          advertising. Give the impression that they emanate from or are
          endorsed by us or any other person or entity, if this is not the case.
          Reliance on Information Posted The information presented on or through
          the Website is made available solely for general information purposes.
          We do not warrant the accuracy, completeness, or usefulness of this
          information. Any reliance you place on such information is strictly at
          your own risk. We disclaim all liability and responsibility arising
          from any reliance placed on such materials by you or any other visitor
          to the Website, or by anyone who may be informed of any of its
          contents. This Website may include content provided by third parties,
          including materials provided by other users, bloggers, and third-party
          licensors, syndicators, aggregators, and/or reporting services. All
          statements and/or opinions expressed in these materials, and all
          articles and responses to questions and other content, other than the
          content provided by the Company, are solely the opinions and the
          responsibility of the person or entity providing those materials.
          These materials do not necessarily reflect the opinion of the Company.
          We are not responsible, or liable to you or any third party, for the
          content or accuracy of any materials provided by any third parties.
          Changes to the Website We may update the content on this Website from
          time to time, but its content is not necessarily complete or
          up-to-date. Any of the material on the Website may be out of date at
          any given time, and we are under no obligation to update such
          material. Information About You and Your Visits to the Website All
          information we collect on this Website is subject to our Privacy
          Policy. By using the Website, you consent to all actions taken by us
          with respect to your information in compliance with the Privacy
          Policy. Linking to the Website and Social Media Features You may link
          to our homepage, provided you do so in a way that is fair and legal
          and does not damage our reputation or take advantage of it, but you
          must not establish a link in such a way as to suggest any form of
          association, approval, or endorsement on our part with our express
          written consent. This Website may provide certain social media
          features that enable you to: Link from your own or certain third-party
          websites to certain content on this Website. Send emails or other
          communications with certain content, or links to certain content, on
          this Website. Cause limited portions of content on this Website to be
          displayed or appear to be displayed on your own or certain third-party
          websites. Subject to the foregoing, you must not: Establish a link
          from any website that is not owned by you. Cause the Website or
          portions of it to be displayed on, or appear to be displayed by, any
          other site, for example, framing, deep linking, or in-line linking.
          Link to any part of the Website other than the homepage. Otherwise
          take any action with respect to the materials on this Website that is
          inconsistent with any other provision of these Terms of Use. You agree
          to cooperate with us in causing any unauthorized framing or linking
          immediately to stop. We reserve the right to withdraw linking
          permission without notice. We may disable all or any social media
          features and any links at any time without notice in our discretion.
          Links from the Website If the Website contains links to other sites
          and resources provided by third parties, these links are provided for
          your convenience only. This includes links contained in
          advertisements, including banner advertisements and sponsored links.
          Spark cannot provide any guarantee for the content, quality, nature or
          reliability of these external websites. The placement of a link does
          not imply any support or approval of the information, or services
          offered on the respective pages. We have no control over the contents
          of those sites or resources and accept no responsibility for them or
          for any loss or damage that may arise from your use of them. If you
          decide to access any of the third-party websites linked to this
          Website, you do so entirely at your own risk and subject to the terms
          and conditions use for such websites. The respective provider or
          operator of the sites is solely responsible for the content of the
          linked pages. Constant monitoring of the contents of the linked sites
          is not possible without specific evidence of a legal violation. If
          specific indications of legal violations are received, such links will
          be removed from the Spark website immediately. Geographic Restrictions
          The owner of the Website is based in the state of Delaware in the
          United States. Access to the Website may not be legal by certain
          persons or in certain countries. If you access the Website from
          outside the United States, you do so on your own initiative and are
          responsible for compliance with local laws. Disclaimer of Warranties
          You understand that we cannot and do not guarantee or warrant that
          files available for downloading from the internet or the Website will
          be free of viruses or other destructive code. You are responsible for
          implementing sufficient procedures and checkpoints to satisfy your
          particular requirements for anti-virus protection and accuracy of data
          input and output, and for maintaining a means external to our site for
          any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY
          LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
          DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
          TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
          EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE
          TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH
          THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON
          ANY WEBSITE LINKED TO IT. YOUR USE OF THE WEBSITE, ITS CONTENT, AND
          ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN
          RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED
          THROUGH THE WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
          BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
          NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES
          ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
          SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE
          WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR
          ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE
          WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
          WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT
          DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT
          AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE
          WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL
          OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. TO THE FULLEST EXTENT
          PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY
          KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING
          BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
          NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE. THE FOREGOING
          DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED
          UNDER APPLICABLE LAW. Limitation of Liability TO THE FULLEST EXTENT
          PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR
          THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR
          DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY,
          ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE,
          THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR
          SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
          INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT
          LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS,
          LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
          SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER
          CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR
          OTHERWISE, EVEN IF FORESEEABLE. THE FOREGOING DOES NOT AFFECT ANY
          LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          Indemnification You agree to defend, indemnify, and hold harmless the
          Company, its affiliates, licensors, and service providers, and its and
          their respective officers, directors, employees, contractors, agents,
          licensors, suppliers, successors, and assigns from and against any
          claims, liabilities, damages, judgments, awards, losses, costs,
          expenses, or fees (including reasonable attorneys’ fees) arising out
          of or relating to your violation of these Terms of Use or your use of
          the Website, including, but not limited to, your User Contributions,
          any use of the Website’s content, services, and products other than as
          expressly authorized in these Terms of Use or your use of any
          information obtained from the Website. Governing Law and Jurisdiction
          All matters relating to the Website and these Terms of Use and any
          dispute or claim arising therefrom or related thereto (in each case,
          including non-contractual disputes or claims), shall be governed by
          and construed in accordance with the internal laws of the State of
          Delaware without giving effect to any choice or conflict of law
          provision or rule (whether of the State of Delaware or any other
          jurisdiction). Any legal suit, action, or proceeding arising out of,
          or related to, these Terms of Use or the Website shall be instituted
          exclusively in the federal courts of the United States or the courts
          of the State of Delaware. You waive any and all objections to the
          exercise of jurisdiction over you by such courts and to venue in such
          courts. Waiver and Severability No waiver of by the Company of any
          terms and conditions set out in these Terms of Use shall be deemed a
          further or continuing waiver of such term or condition or a waiver of
          any other terms or condition, and any failure of the Company to assert
          a right or provision under these Terms of Use shall not constitute a
          waiver of such right or provision. If any provision of these Terms of
          Use is held by a court or other tribunal of competent jurisdiction to
          be invalid, illegal or unenforceable for any reason, such provision
          shall be eliminated or limited to the minimum extent such that the
          remaining provisions of the Terms of Use will continue in full force
          and effect. Your Comments and Concerns The website is operated by
          Spark Founders LLC All notices of copyright infringement claims should
          be sent to 8 The Green 19321, Dover DE 19901, Kent, DE, USA. Your
          purchase and subscription If you are outside the United States, your
          purchase may be subject to foreign exchange fees or differences in
          prices based on location (e.g. exchange rates). By completing the form
          online to register for a service, we may store and continue billing
          your payment method (e.g. credit card) even after it has expired, to
          avoid interruptions in your services and to use to pay other services
          you may buy. If you purchase a subscription, your payment method
          automatically will be charged at the start of each subscription period
          for the fees and taxes applicable to that period. To avoid future
          charges, cancel before the renewal date. By signing up for a
          subscription service, you agree that after the 30 day initial trial
          period, you consent to automatic payments or debit on your credit
          card. If you want to cancel, refer to the cancellation and refund
          process. If you make a purchase using a direct debit, you are entitled
          to a refund from your bank under the terms and conditions of your
          agreement with your bank. Please refer to your bank agreement
          regarding credit card fees, charges and debit cards. If you have
          questions about your subscription or payment, please contact Spark at
          contact@spark-founders.com
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default TermsConditions;
