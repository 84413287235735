import React, { useEffect } from "react";
import { DNA } from "react-loader-spinner";

const LoaderSpinner = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <>
      <div style={styles.overlay}></div>
      <div style={styles.loader}>
        <DNA visible={true} height={100} width={100} />
      </div>
    </>
  );
};

export default LoaderSpinner;

const styles = {
  loader: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 999999,
  },
  overlay: {
    background: "#000",
    height: "100%",
    width: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    opacity: 0.6,
    zIndex: 999998,
  },
};
