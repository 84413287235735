import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { api } from "../../Services/api-service";
import notificationSvc from "../../Services/notification";
import { FaArrowRight } from "react-icons/fa6";
import Footer from "../../components/footer";
import GoogleOAuth from "../../components/OAuth/GoogleOAuth";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from "../../utils/constans";
import LinkedinOAuth from "../../components/OAuth/LinkedinOAuth";

const Signup = ({ isLoggedIn }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    refCode: "",
  });

  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const refCode = queryParams.get('ref');

  useEffect(() =>
    setFormData({
      ...formData,
      refCode: refCode,
    }), [formData, refCode]);

  const register = async (e) => {
    e.preventDefault();
    if (!isTermsAccepted) {
      notificationSvc.error(
        "You must accept the terms and conditions to proceed."
      );
      return;
    }
    try {
      const response = await api.post("users/signup", formData);
      if ((response && response.status === 200) || response.status === 201) {
        notificationSvc.success(response.data.message);
        navigate("/login");

        // localStorage.setItem("idToken", response.data.token);
        // const tokenData = jwtDecode(response.data.token);

        // localStorage.setItem("user", JSON.stringify(tokenData));
        // localStorage.setItem("refreshToken", response.data.refreshToken);
        // localStorage.setItem("profileCreated", false);
        // onSignup();
        // navigate("/profile-setup", { replace: true });
      }
    } catch (error) {
      notificationSvc.error(error);
    }
  };

  useEffect(() => {
    if (isLoggedIn)
      navigate('/')
  }, [isLoggedIn, navigate])

  return (
    <>
      <div className="h-screen w-full overflow-x-hidden flex flex-col justify-between">

        <div className="px-4 w-full flex flex-col gap-6 mt-6 md:mt-14">
          <div className="flex flex-col justify-center items-center mt-20">
            <h1 className="md:text-3xl text-2xl text-[#212121] font-[600]">
              Register to Get Matches
            </h1>
          </div>

          <div>
            <div className="mt-7 flex flex-col items-center gap-2">
              <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <GoogleOAuth />
              </GoogleOAuthProvider>
              <LinkedinOAuth />
            </div>
          </div>
          <div className="flex justify-center w-full">
            <div className="flex w-full md:w-[315px] items-center gap-2 py-0 text-sm text-slate-600">
              <div className="h-px w-full bg-slate-200"></div>
              OR
              <div className="h-px w-full bg-slate-200"></div>
            </div>
          </div>
          <div className="flex justify-center md:flex-row flex-col mb-5">
            <form
              onSubmit={(e) => {
                register(e);
              }}
            >
              <div className="mb-5 md:mt-0 mt-5">
                <input
                  type="text"
                  className="h-[48px] border md:w-[315px] w-full border-[#D4D6D8] text-gray-900 pl-[12px] rounded-lg "
                  required
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      firstName: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="mb-5 md:mt-0 mt-5">
                <input
                  type="text"
                  className="h-[48px] border md:w-[315px] w-full border-[#D4D6D8] text-gray-900 pl-[12px] rounded-lg "
                  required
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      lastName: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="mb-5 md:mt-0 mt-5">
                <input
                  type="email"
                  className="h-[48px] border md:w-[315px] w-full border-[#D4D6D8] text-gray-900 pl-[12px] rounded-lg "
                  required
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      email: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="mb-5">
                <input
                  type="password"
                  className="h-[48px] md:w-[315px] w-full border border-[#D4D6D8] text-gray-900 pl-[12px] rounded-lg "
                  required
                  placeholder="Password"
                  value={formData.password}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      password: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="mb-5 flex items-center justify-start">
                <input
                  type="checkbox"
                  id="terms"
                  className="mr-2"
                  checked={isTermsAccepted}
                  onChange={(e) => setIsTermsAccepted(e.target.checked)}
                />
                <label htmlFor="terms" className="text-gray-900">
                  I accept the{" "}
                  <a
                    href="/terms-and-conditions"
                    className="text-spark-500"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions
                  </a>
                </label>
              </div>

              <button
                type="submit"
                className="mb-5 flex h-12 w-full rounded-lg bg-spark-500 lg:justify-around gap-4 justify-center lg:items-center text-white text-sm font-[500] p-3"
              >
                <p>Register your Account</p>

                <FaArrowRight className="text-2xl" />
              </button>

              <p className="text-gray-400 text-center">
                Already have an account?&nbsp;
                <Link to="/login" className="text-spark-500">
                  Login
                </Link>
              </p>
            </form>
          </div>
        </div>
        {/* Body end*/}
        <Footer />
      </div>
    </>
  );
};

export default Signup;
