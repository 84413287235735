import React from "react";
import NavbarHeader from "../../components/NavbarHeader";
import Footer from "../../components/footer";

function Zero2One() {
  return (
    <div className="w-[100vw] min-h-[100vh] overflow-x-hidden bg-white">
      <NavbarHeader className2="text-gray-700 "/>

      <div className="flex justify-center mt-[250px]">
        <iframe
          src="https://startupstack.com/partners/spark-founders"
          width="80%"
          height="800"
          frameBorder="0"
          allowFullScreen
          title="Example iframe"
          className="border-none bg-white"
        />
      </div>

      <div className="mt-[200px]">
        <Footer marginTop="200" />
      </div>
    </div>
  );
}

export default Zero2One;
