import React from "react";
import NavbarHeader from "../../components/NavbarHeader";
import Footer from "../../components/footer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { api } from "../../Services/api-service";
import notificationSvc from "../../Services/notification";

const Email = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();

  const verifyEmail = async () => {
    const response = await api.get(`users/verifyEmail?token=${token}`);
    if (response && response.status === 200) {
      navigate("/login");
      notificationSvc.success("Email has been verified");
    }
  };

  return (
    <div>
      <NavbarHeader />
      <div class="relative flex h-[90vh] flex-col items-center justify-center overflow-hidden py-6 sm:py-12 bg-white">
        <div class="max-w-xl px-5 text-center">
          <h2 class="mb-2 text-[42px] font-bold text-zinc-800">
            Click Here to Verify Email
          </h2>
          <button
            onClick={() => {
              verifyEmail();
            }}
            class="mt-3 inline-block w-96 rounded bg-spark-500 px-5 py-3 font-medium text-white shadow-md shadow-indigo-500/20 hover:bg-opacity-70"
          >
            Verify →
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Email;
