import { configureStore } from "@reduxjs/toolkit";

import activePlanReducer from "./activePlanSlice.js";
import portalSlice from "./portalSlice.js";
import attributeSlice from "./attributeSlice.js";

export const store = configureStore({
  reducer: {
    activePlan: activePlanReducer,
    portal: portalSlice,
    attributes: attributeSlice,
  },
});
