"use client";
import React from "react";
import { BackgroundBeams } from "../../components/ui/background-beams.tsx";
import NavbarHeader from "../../components/NavbarHeader.js";
import Footer from "../../components/footer.js";

export function Northstar() {
  return (
    <div className="w-[100vw] overflow-x-hidden bg-white">
      <div className="bg-spark-500 ">
        <NavbarHeader />
      </div>

      <div className="h-[90vh] xl:h-[90vh] w-full rounded-bl-md rounded-br-md bg-spark-500 relative flex flex-col items-center justify-center antialiased">
        <div className="absolute inset-0 flex flex-col items-center justify-center text-white font-bold px-4 pointer-events-none text-3xl text-center md:text-4xl lg:text-7xl">
          <p className="bg-clip-text md:text-5xl text-xl xl:text-5xl  text-transparent drop-shadow-2xl bg-gradient-to-b from-white/80 to-white/80 w-[80%] lg:w-[50%]">
            Empowering your journey Mentoring and Coaching Expert Support
            Transform your path
          </p>
        </div>
        <BackgroundBeams />
      </div>

      <div className="md:px-[10%] px-[5%] bg-spark-500 md:m-[60px] my-[60px] py-[60px]">
        <div className="heading mb-5 text-xl font-semibold text-white">
          One stop shop to your success…
        </div>

        <div className="grid md:grid-cols-3 gap-4">
          <div className="bg-white rounded-2xl py-5 px-8 min-h-[200px]">
            <h2 className="heading mb-5 text-[20px] font-extrabold text-spark-500">
              Leadership
            </h2>
            <p className="heading  text-[18px] font-bold text-spark-500 mt-[50px]">
              Inspire and lead with confidence
            </p>
          </div>

          <div className="bg-white rounded-2xl py-5 px-8 min-h-[200px]">
            <h2 className="heading mb-5 text-[20px] font-extrabold text-spark-500">
              Marketing
            </h2>
            <p className="heading  text-[18px] font-bold text-spark-500 mt-[50px]">
              Master the art of market influence
            </p>
          </div>

          <div className="bg-white rounded-2xl py-5 px-8 min-h-[200px]">
            <h2 className="heading mb-5 text-[20px] font-extrabold text-spark-500">
              Sales
            </h2>
            <p className="heading  text-[18px] font-bold text-spark-500 mt-[50px]">
              Boost your sales with proven techniques
            </p>
          </div>

          <div className="bg-white rounded-2xl py-5 px-8 min-h-[200px]">
            <h2 className="heading mb-5 text-[20px] font-extrabold text-spark-500">
              Strategy
            </h2>
            <p className="heading  text-[18px] font-bold text-spark-500 mt-[50px]">
              Craft winning strategies for success
            </p>
          </div>

          <div className="bg-white rounded-2xl py-5 px-8 min-h-[200px]">
            <h2 className="heading mb-5 text-[20px] font-extrabold text-spark-500">
              Finance
            </h2>
            <p className="heading  text-[18px] font-bold text-spark-500 mt-[50px]">
              Navigate your finances like a pro
            </p>
          </div>

          <div className="bg-white rounded-2xl py-5 px-8 min-h-[200px]">
            <h2 className="heading mb-5 text-[20px] font-extrabold text-spark-500">
              Innovation
            </h2>
            <p className="heading  text-[18px] font-bold text-spark-500 mt-[50px]">
              Spark creativity and drive innovation
            </p>
          </div>

          <div className="bg-white rounded-2xl py-5 px-8 min-h-[200px]">
            <h2 className="heading mb-5 text-[20px] font-extrabold text-spark-500">
              Operations
            </h2>
            <p className="heading  text-[18px] font-bold text-spark-500 mt-[50px]">
              Optimize operations for peak performance
            </p>
          </div>

          <div className="bg-white rounded-2xl py-5 px-8 min-h-[200px]">
            <h2 className="heading mb-5 text-[20px] font-extrabold text-spark-500">
              Networking
            </h2>
            <p className="heading  text-[18px] font-bold text-spark-500 mt-[50px]">
              Build connections that matter
            </p>
          </div>

          <div className="  min-h-[200px]">
            <h2 className="heading lg:text-[45px] md:text-[37px] text-[45px]  font-bold text-white">
              Mastery is the path to excellence…..
            </h2>
          </div>
        </div>
      </div>

      <div className="mt-[200px]">
        <Footer marginTop="200" />
      </div>
    </div>
  );
}
