import authSvc from "./auth-service";
import notificationSvc from "./notification";
import spinnerSvc from "./spinner.js";
import axios from "axios";
import { API_BASE_URL } from "../utils/constans";

export const api = axios.create({
  baseURL: API_BASE_URL,
  headers: { Accept: "application/json" },
});

api.interceptors.request.use(
  async (config) => {
    if (config.url.includes("attachment")) {
    } else {
      spinnerSvc.start();
    }

    const token = localStorage.getItem("idToken") || "";

    if (token !== "") {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (err) => console.error("Error", err)
);

api.interceptors.response.use(
  (response) => {
    spinnerSvc.stop();
    return response;
  },
  async (err) => {
    spinnerSvc.stop();
    const originalRequest = err.config;

    if (err.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const accessToken = await authSvc.refresh();

      if (accessToken) {
        api.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
        return api(originalRequest);
      } else {
        authSvc.logout();
        return;
      }
    }

    console.error(err.response.data);

    switch (err.response?.status) {
      case 400:
        spinnerSvc.stop();
        return notificationSvc.error(err.response.data.error.errors[0]);

      case 401:
        localStorage.clear();
        setTimeout(() => window.location.href = "/login", 3000)
        notificationSvc.error('User not logged in');
        spinnerSvc.stop();
        return;

      case 403:
        localStorage.clear();
        if (err.response?.data?.error?.errors[0] === 'Email not verified') {
          notificationSvc.error(err.response?.data?.error?.errors[0] + ', Check your mail inbox or spam folder');
        } else {
          setTimeout(() => window.location.href = "/login", 3000)
          notificationSvc.error('User not logged in');
        }

        spinnerSvc.stop();
        return;

      case 404:
        notificationSvc.error("Item not found");
        spinnerSvc.stop();
        return;

      case 500:
        notificationSvc.error(
          "Unexpected error Please contact your administrator"
        );
        console.error(err);
        spinnerSvc.stop();
        return;

      case 409:
        notificationSvc.error(err.response.data.error.errors[0]);
        console.log("Error", err);
        spinnerSvc.stop();
        return;

      case 502:
        const errMsg = err.response.data.error.errors[0];
        const match = errMsg.match(/wait (\d+) sec/);

        if (match) {
          const estimatedTime = match[1];
          notificationSvc.warning(`We are loading you matches, please wait ~ ${parseInt(estimatedTime) + 10} sec`)
          setTimeout(() => {
            window.location.reload();
          }, parseInt((estimatedTime + 10) * 1000));
        } else {
          notificationSvc.error('Please wait while server is responding');
        }
        break;
      default:
        notificationSvc.error(
          "Unexpected error Please contact your administrator"
        );
        spinnerSvc.stop();
        return;
    }
  }
);
