import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../Services/api-service";
import notificationSvc from "../../Services/notification";
import NavbarHeader from "../../components/NavbarHeader";
import Footer from "../../components/footer";
import { FaArrowRight } from "react-icons/fa6";

const ForgotPassword = ({ isLoggedIn }) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("users/forgot-password", { email });
      if (response && (response.status === 200 || response.status === 201)) {
        notificationSvc.success("Password reset link sent to your email.");
        navigate("/login");
      }
    } catch (error) {
      notificationSvc.error("Failed to send password reset email. Please try again.");
    }
  };

  useEffect(() => {
    if (isLoggedIn)
      navigate('/')
  }, [isLoggedIn, navigate])
  return (
    <>
      <div className="h-screen w-full overflow-x-hidden flex flex-col justify-between">
        <NavbarHeader />

        <div className="px-4 w-full flex flex-col gap-6 mt-6 md:mt-14">
          <div className="flex flex-col justify-center items-center mt-20">
            <h1 className="md:text-3xl text-2xl text-[#212121] font-[600]">
              Forgot Password
            </h1>
            <p className="text-gray-500 text-center mt-2">
              Enter your email address below to reset your password.
            </p>
          </div>

          <div className="flex justify-center md:flex-row flex-col mb-5">
            <form onSubmit={handleForgotPassword}>
              <div className="mb-5 md:mt-0 mt-5">
                <input
                  type="email"
                  className="h-[48px] border md:w-[315px] w-full border-[#D4D6D8] text-gray-900 pl-[12px] rounded-lg"
                  required
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <button
                type="submit"
                className="mb-5 flex h-12 w-full rounded-lg bg-spark-500 lg:justify-around gap-4 justify-center lg:items-center text-white text-sm font-[500] p-3"
              >
                <p>Send Reset Link</p>
                <FaArrowRight className="text-2xl" />
              </button>

              <p className="text-gray-400 text-center">
                Remembered your password?&nbsp;
                <a href="/login" className="text-spark-500">
                  Login
                </a>
              </p>
            </form>
          </div>
        </div>
        {/* Body end*/}

        <Footer />
      </div>
    </>
  );
};

export default ForgotPassword;
