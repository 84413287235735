import React, { useEffect, useState } from "react";
import "./logincss.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import authSvc from "../../Services/auth-service";
import { FaArrowRight } from "react-icons/fa6";
import Footer from "../../components/footer";
import GoogleOAuth from "../../components/OAuth/GoogleOAuth";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from "../../utils/constans";
import LinkedinOAuth from "../../components/OAuth/LinkedinOAuth";

const Login = ({ onLogin, isLoggedIn }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const login = async (e) => {
    e.preventDefault();
    const { isLoggedIn, profileCreated } = await authSvc.login(
      formData.email,
      formData.password
    );

    if (isLoggedIn) {
      onLogin();

      if (profileCreated) {
        navigate("/");
      } else {
        navigate("/profile-setup");
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn)
      navigate('/')
  }, [isLoggedIn, navigate])

  return (
    <>
      <div className="h-screen w-full overflow-x-hidden flex flex-col justify-between">
        {/* <NavbarHeader /> */}

        {/* Body */}
        <div className="px-4 mt-48 m-auto w-full flex flex-col gap-[42px]">
          {/* heading */}
          <div className="flex flex-col justify-center items-center">
            {/* h1 */}

            <h1 className="md:text-4xl text-2xl text-[#212121] font-[600] mb-4">
              Login into your account
            </h1>

            {/* para */}

            <p className="text-base text-center text-[#828282] font-[400]">
              Login into your account and join Spark, the Internet's premier
              startup community. Supercharge your business journey!
            </p>
          </div>
          <div>
            <div className="mt-7 flex flex-col items-center gap-2">
              <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <GoogleOAuth />
              </GoogleOAuthProvider>
              <LinkedinOAuth />
            </div>
          </div>
          <div className="flex justify-center w-full">
            <div className="flex w-full md:w-[315px] items-center gap-2 py-0 text-sm text-slate-600">
              <div className="h-px w-full bg-slate-200"></div>
              OR
              <div className="h-px w-full bg-slate-200"></div>
            </div>
          </div>
          {/* form */}
          <div className="flex justify-center md:flex-row flex-col mb-5">
            {/* left */}
            <form
              onSubmit={(e) => {
                login(e);
              }}
            >
              <div className="mb-5 md:mt-0 mt-[28px]">
                <input
                  type="email"
                  className="h-[48px] border md:w-[315px] w-full border-[#D4D6D8] text-gray-900 pl-[12px] rounded-lg "
                  required
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      email: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="mb-5">
                <input
                  type="password"
                  className="h-[48px] md:w-[315px] w-full border border-[#D4D6D8] text-gray-900 pl-[12px] rounded-lg "
                  required
                  placeholder="Password"
                  value={formData.password}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      password: e.target.value,
                    });
                  }}
                />
              </div>
              <p className="text-gray-400 text-end">
                <Link to="/forgot-password" className="text-spark-500">
                  Forgot Password?
                </Link>
              </p>
              <button
                type="submit"
                className="mb-5 mt-5 flex h-[48px] md:w-[315px] w-full rounded-lg bg-spark-500 lg:justify-around gap-4 justify-center lg:items-center text-white text-[14px]  font-[500] leading-[30px] p-[12px]"
              >
                <p>Login To your Account</p>

                <FaArrowRight className="text-2xl" />
              </button>

              <p className="text-gray-400">
                Don't have an account?&nbsp;
                <Link to="/register" className="text-spark-500">
                  Create account
                </Link>
              </p>
            </form>

            {/* center */}
          </div>
        </div>
        {/* Body end*/}

        <Footer />
      </div>
    </>
  );
};

export default Login;
