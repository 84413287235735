import React from "react";
import sparkLogo from "../assets/images/sparkLogo.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="text-center md:flex lg:flex md:flex-row gap-9 md:justify-between items-center lg:justify-between md:px-16 mb-0 pb-10">
      <div className="flex justify-center md:justify-start lg:justify-start items-center mb-4 md:mb-0 lg:mb-0">
        <Link to="/">
          <img src={sparkLogo} alt="Spark Logo" className="w-24" />
        </Link>
      </div>

      <div className="flex flex-col md:flex-row lg:flex-row gap-4 items-center">
        <Link to="/privacy-policy" className="text-spark-500">
          Privacy Policy
        </Link>
        <p>Copyright © {new Date().getFullYear()} Spark</p>
      </div>
    </footer>
  );
}

export default Footer;
