import React, { useCallback, useEffect, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Dashboard from "./pages/Dasboard/Dashboard";
import Login from "./pages/Login/login";
import UnlockPotential from "./pages/UnlockYourPotential/profileSetup.jsx";
import About from "./pages/About/about";
import { Northstar } from "./pages/Northstar/northstar";
import Zero2One from "./pages/0to1/Zero2One";
import spinnerSvc from "./Services/spinner";
import LoaderSpinner from "./components/Loader";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Signup from "./pages/Signup/Signup";
import Success from "./components/CheckoutStatus/Success";
import Failure from "./components/CheckoutStatus/Failure";
import Conflict from "./components/CheckoutStatus/Conflict";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy.jsx";
import TermsConditions from "./pages/TermsConditions/TermsConditions.jsx";
import Chat from "./pages/Chat/Chat.jsx";
import Navbar from "./components/navbar.js";
import "react-responsive-modal/styles.css";
import { api } from "./Services/api-service.js";
import { useDispatch, useSelector } from "react-redux";
import { fetchActivePlan } from "./redux/activePlanSlice.js";
import ForgotPassword from "./pages/ForgotPassword/FrogotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Update from "./pages/Profile/Update.jsx";
import "preline/preline";
import Email from "./pages/Verification/Email.jsx";
import LinkedInRedirect from "./pages/LinkedInRedirect";

const ProtectedRoute = ({ isLoggedIn, children }) => {
  return isLoggedIn ? children : <Navigate to="/login" />;
};

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const publicRoutes = [
    "/login",
    "/about",
    "/0to1",
    "/northstar",
    "/register",
    "/privacy-policy",
    "/terms-and-conditions",
    "/forgot-password",
    "/reset-password",
  ];

  const verifyProfile = useCallback(async () => {
    const profileCreated = localStorage.getItem("profileCreated");
    if (profileCreated !== "true") {
      navigate("/profile-setup");
    }
  }, [navigate]);

  const getPlans = async () => {
    const response = await api.get("plans");
    if (response && response.status === 200) {
      setPlans(response.data);
    }
  };

  const dispatch = useDispatch();
  const activePlan = useSelector((state) => state.activePlan.activeplan);

  useEffect(() => {
    if (isLoggedIn && !activePlan) {
      dispatch(fetchActivePlan());
    }
  }, [dispatch, activePlan, isLoggedIn]);

  const getActivePlan = () => {
    if (activePlan === null) {
      return { planName: "Free", planId: null };
    } else {
      const activatedPlan = plans.find((plan) => {
        return plan.planId === activePlan.planId;
      });
      return activatedPlan;
    }
  };

  const validateLoggedInStatus = useCallback(async () => {
    const token = localStorage.getItem("idToken");
    setIsLoggedIn(!!token);
    setIsAuthChecked(true);
  }, []);

  useEffect(() => {
    validateLoggedInStatus();
  }, [validateLoggedInStatus]);

  useEffect(() => {
    if (isLoggedIn) {
      getPlans();
      verifyProfile();
    }
  }, [isLoggedIn, verifyProfile]);

  useEffect(() => {
    const subscription = spinnerSvc.requestInProgress.subscribe((isLoading) =>
      setIsLoading(isLoading)
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [location.pathname]);

  if (!isAuthChecked) {
    return <LoaderSpinner />;
  }

  return (
    <div className="App">
      <ToastContainer
        hideProgressBar={true}
        autoClose={5000}
        transition={Bounce}
      />
      {isLoading && <LoaderSpinner />}

      {!publicRoutes.includes(location.pathname) && isLoggedIn && (
        <Navbar
          firstName={user?.firstName}
          lastName={user?.lastName}
          displaypicture={user?.profilePicture?.publicUrl}
          getActivePlan={getActivePlan}
        />
      )}

      <Routes>
        {/* Public Routes */}
        <Route
          path="/login"
          element={
            <Login
              isLoggedIn={isLoggedIn}
              onLogin={() => setIsLoggedIn(true)}
            />
          }
        />
        <Route path="/about" element={<About />} />

        <Route path="/0to1" element={<Zero2One />} />

        <Route path="/northstar" element={<Northstar />} />

        <Route path="/register" element={<Signup isLoggedIn={isLoggedIn} />} />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/terms-and-conditions" element={<TermsConditions />} />

        <Route
          path="/verify-email"
          element={<Email />}
        />

        <Route
          path="/forgot-password"
          element={<ForgotPassword isLoggedIn={isLoggedIn} />}
        />

        <Route
          path="/reset-password"
          element={<ResetPassword isLoggedIn={isLoggedIn} />}
        />

        {/* Protected Routes */}
        <Route
          path="/"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile-setup"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <UnlockPotential />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chat"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Chat />
            </ProtectedRoute>
          }
        />
        <Route
          path="/checkout-success"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Success />
            </ProtectedRoute>
          }
        />
        <Route
          path="/checkout-failure"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Failure />
            </ProtectedRoute>
          }
        />
        <Route
          path="/checkout-conflict"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Conflict />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-profile/update"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Update />
            </ProtectedRoute>
          }
        />
        <Route
          path="*"
          element={<Navigate to={isLoggedIn ? "/" : "/login"} />}
        />
        <Route path="/linkedin" element={<LinkedInRedirect />} />
      </Routes>
    </div>
  );
}

export default App;
