import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import Slider from "react-slick";
import { isMobile } from "react-device-detect";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function CheckList({ step, handleSave }) {
  const steps = [
    {
      title: "Create Account",
      description: "Your Account has been created and validated",
    },
    {
      title: "Personal Information",
      description: "Tell us more about you",
    },
    {
      title: "Profile Image",
      description: "Upload your picture",
    },
    {
      title: "Your Role",
      description: "What do you bring?",
    },
    {
      title: "Looking For",
      description: "What do you need?",
    },
    {
      title: "You're Skills",
      description: "What are you best at?",
    },
    {
      title: "Interests",
      description: "Which industries are you interested in?",
    },
  ];

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
    initialSlide: step - 1,
    beforeChange: (current, next) => {
      if (next !== step - 1) {
        handleSave();
      }
    },
  };

  return (
    <div
      className={`flex flex-col gap-5 rounded-[12px] py-[40px]  bg-white ${
        isMobile ? "w-full" : "lg:w-1/4 pl-[39px] pr-[39px]"
      } mt-4 lg:mt-0`}
    >
      {isMobile ? (
        <Slider {...settings}>
          {steps.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center text-center px-2"
            >
              <span
                className={`flex items-center mx-auto justify-center w-8 h-8 rounded-full ring-4 ring-white ${
                  step > index + 1
                    ? "bg-spark-500"
                    : step === index + 1
                    ? "bg-purple-100"
                    : "bg-gray-100"
                } dark:ring-gray-900`}
              >
                <FaCheckCircle
                  className={`w-[16px] h-[16px] ${
                    step > index + 1
                      ? "text-white"
                      : step === index + 1
                      ? "text-spark-500"
                      : "text-gray-400"
                  }`}
                />
              </span>
              <div className="w-full mt-2">
                <h5 className="text-sm text-[#212121] font-[600]">
                  {item.title}
                </h5>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <ol className="relative text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400">
          {steps.map((item, index) => (
            <li key={index} className={`mb-8 ms-6`}>
              <span
                className={`absolute flex items-center justify-center w-8 h-8 rounded-full -start-4 ring-4 ring-white ${
                  step > index + 1
                    ? "bg-spark-500"
                    : step === index + 1
                    ? "bg-purple-100"
                    : "bg-gray-100"
                } dark:ring-gray-900`}
              >
                <FaCheckCircle
                  className={`w-[16px] h-[16px] ${
                    step > index + 1
                      ? "text-white"
                      : step === index + 1
                      ? "text-spark-500"
                      : "text-gray-400"
                  }`}
                />
              </span>
              <div className="w-full">
                <h5 className="mb-1 text-[16px] text-[#212121] font-[600]">
                  {item.title}
                </h5>
                <p className="text-[#828282] text-[12px] font-[400]">
                  {item.description}
                </p>
              </div>
            </li>
          ))}
        </ol>
      )}
    </div>
  );
}

export default CheckList;
